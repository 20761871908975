//Parametros internacionales por pais

 export const internacional = [
  {
    country: "Colombia",
    code: "co",
    currency: "COP",
    currency_symbol: "$",
    currency_name: "Peso Colombiano",
    currency_decimals: 2,
    currency_decimals_separator: ",",
    currency_thousands_separator: ".",
    tax_id_label: "NIT",
    purchase_tax_label: "IVA",
    region_label: "Departamento",
    comune_label: "Municipio",
    emogi_flag: "🇨🇴",
  },
  {
    country: "Perú",
    code: "pe",
    currency: "PEN",
    currency_symbol: "S/",
    currency_name: "Sol Peruano",
    currency_decimals: 2,
    currency_decimals_separator: ".",
    currency_thousands_separator: ",",
    tax_id_label: "RUC",
    purchase_tax_label: "IGV",
    region_label: "Departamento",
    comune_label: "Provincia",
    emogi_flag: "🇵🇪",
  },
  {
    country: "Chile",
    code: "cl",
    currency: "CLP",
    currency_symbol: "$",
    currency_name: "Peso Chileno",
    currency_decimals: 0,
    currency_decimals_separator: ",",
    currency_thousands_separator: ".",
    tax_id_label: "RUT",
    purchase_tax_label: "IVA",
    region_label: "Región",
    comune_label: "Comuna",
    emogi_flag: "🇨🇱",
  },
  {
    country:"Venezuela",
    code:"ve",
    currency:"VES",
    currency_symbol:"Bs. ",
    currency_name:"Bolívar Soberano",
    currency_decimals:2,
    currency_decimals_separator:",",
    currency_thousands_separator:".",
    tax_id_label:"RIF",
    purchase_tax_label:"IVA",
    region_label:"Estado",
    comune_label:"Municipio",
    emogi_flag:"🇻🇪",
  }
];


export default function getInternacional(code) {
  return internacional.find((element) => element.code === code) || internacional[0];
};