import getInternacional from './internacional'

export const moneda=(value,code='cl')=>{
  const { currency_symbol, currency_decimals, currency_decimals_separator, currency_thousands_separator } = getInternacional(code);
  try {
    if (value === null || value === undefined) {
      return currency_symbol + '0';
    }
    const parsedValue = parseFloat(value);
    const fixed = parsedValue.toFixed(currency_decimals);
    const formated = fixed.replace(/\./g, currency_decimals_separator);
    const formated2 = formated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, currency_thousands_separator);
    return currency_symbol + formated2;
  }
  catch (e) {
    console.error(e, 'VALUE: ',value);
    return currency_symbol + '0';
  }
}