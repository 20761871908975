<template>
<v-container>
  <v-row justify="space-between" class="py-2">
    <div class="text text-caption"><v-icon class="mr-2" title="Procesos">fas fa-cogs</v-icon>Procesos</div>
    <div v-if="!readonly">
      <v-tooltip top>
        <template v-slot:activator="{ on,attrs }">
          <v-btn small @click="addingProcess=true" class="primary--text" v-bind="attrs" v-on="on" :disabled='disabled'>
            <v-icon class="mr-2" small>fas fa-cogs</v-icon>
            Procesos
          </v-btn>
        </template>
        <span>Agregar Procesos</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on,attrs }">
          <v-btn small class="ml-4 primary--text" @click="addingCustom=true" v-bind="attrs" v-on="on" :disabled='disabled'>
            <v-icon class="mr-2" small>fas fa-brush</v-icon>
            <v-icon class="mr-2" small>fas fa-cogs</v-icon>
          </v-btn>
        </template>
        <span>Agregar Proceso Personalizado</span>
      </v-tooltip>
    </div>
  </v-row>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Item
          </th>
          <th class="text-left">
            Descripción
          </th>
          <th class="text-center" title="Cantidad por Producto">
            Cant PP
          </th>
          <th class="text-right" v-if="$can('readCost', 'SalesNote')">
            Costo
          </th>
          <th class="text-left">
            Unidades
          </th>
          <th class="text-right" v-if="$can('readCost', 'SalesNote')">
            Costo Total
          </th>
          <th v-if="!readonly" class="text-center">
            
          </th>
        </tr>
      </thead>

      <draggable 
        :list="items"  
        draggable=".recipe-row-move" 
        tag="tbody"
        :disabled="readonly"
      >
        <tr
          v-for="item in items"
          :key="items.indexOf(item)"
          :class="readonly?'':'recipe-row-move'"
        >
          <td>{{items.indexOf(item)+1}}</td>
          <td>{{item.name}}</td>
          <td>
            <v-text-field
              class="mx-auto"
              style="max-width:90px;"
              dense
              type="number"
              hide-details
              solo
              v-model=item.qty
              :disabled="readonly||disabled"
            ></v-text-field>
            
              <v-chip v-if="item.merma>0" class="float-right mt-1" x-small dark color=" text-center orange darken-1">+ {{item.merma }}</v-chip>
            
          </td>
          <td class="text-right" v-if="$can('readCost', 'SalesNote')">{{item.price | moneda}}</td>
          <td>
            <v-chip label outlined  class="ml-1">
              {{item.unit_name}}
            </v-chip>
          </td>
          <td v-if="$can('readCost', 'SalesNote')" class="text-right">
            {{individualCost(item)|moneda}}
            <v-progress-linear :value="individualCost(item)/total*100"></v-progress-linear>
            <span v-if="!(item.min_price<individualCost(item))">
              <v-chip x-small dark color="info">min.</v-chip>
            </span>
          </td>
        <td v-if="!readonly" class="text-center">
          <v-icon @click="deleteItem(item)" x-small class="error--text" :disabled='disabled'>fas fa-trash</v-icon>
        </td>
        </tr>
      
      </draggable>
    </template>
  </v-simple-table>
  <v-dialog v-model="addingProcess" max-width="800px">
    <v-card class="pa-8 px-10">
      <v-card-title class="titulo text-h4 mb-2">
        <v-icon class="mr-2">fas fa-cogs</v-icon>
        Agregar Proceso</v-card-title>
      <add-cost @add="add" :is_supply="false"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addingCustom" max-width="600px">
    <cost-create-update
      :cost=newCost 
      @save="add"
      :custom='true'
    /> 
  </v-dialog>
</v-container>

</template>
<script>
import AddCost from '@/components/products/costs/AddCost'
import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'
import draggable from 'vuedraggable'

export default {
  name:'Recipe',
  components: { AddCost,CostCreateUpdate,draggable},
  data:()=>({
    addingProcess:false,
    addingSupply:false,
    addingCustom: false,
    newCost:{
      id:-1,
      unit:'',
      name:'',
      price:0,
      qty:1,
      min_price:0,
      group:-1,
      is_supply:false
    },
  }),
  props:['items','mts2','qty','total','readonly','disabled'],
  methods:{
    add(item){
      this.$set(this.items,this.items.length,item)
      this.addingCustom=false,
      this.newCost={
            id:-1,
            unit:'',
            name:'',
            price:0,
            qty:1,
            min_price:0,
            is_supply:'',
            group:-1,
          },
      this.addingProcess=false,
      this.addingSupply=false
    },
    deleteItem(item){
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    //funcion para calcular el costo individual de un item
    //Toma en cuenta la merma
    individualCost(item){
      const costUNIT=item.price*(this.qty*item.qty+item.merma)
      const costMTS2=item.price*this.mts2*item.qty
      const costFIJO=item.price*item.qty
      switch(item.unit){
        case 1:
          return costMTS2>item.min_price?costMTS2:item.min_price
        case 2:
          return costUNIT>item.min_price?costUNIT:item.min_price
        default:
          return costFIJO>item.min_price?costFIJO:item.min_price
      }
    },
    minPriceFlag(item){
      return item.min_price>this.individualCost(item)
    }
  },
  computed:{
    
  }
}
</script>
