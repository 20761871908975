<template>
  <div>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Agregar Registro de Procesos</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form class="mt-2" @submit.prevent="handleSubmit(save)">
          <v-simple-table class="px-4">
            <thead>
              <tr>
                <th class="table-header-2">
                  OT
                </th>
                <th class="table-header-2">
                  Producto
                </th>
                <th v-if='machine.production_unit_m2' class="table-header-2">
                  Total Mts<sup>2</sup>
                </th>
                <th v-if='machine.production_unit_m2' class="table-header-2">
                  Producido Mts<sup>2</sup>
                </th>
                <th v-if='!machine.production_unit_m2' class="table-header-2">
                  Total Unidades
                </th>
                <th v-if='!machine.production_unit_m2' class="table-header-2">
                  Producido Unidades
                </th>
                <th v-if="machine.hour_control">
                  Inicio
                </th>
                <th v-if="machine.hour_control">
                  Termino
                </th>
                <th class="d-flex align-center justify-center table-header-2">
                  Completado?
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="process in processes" :key="process.id">
                <td class="caption">
                  {{process.work_order}}
                </td>
                <td>
                  <div v-if='process.product_sku' class="text text-caption">
                   [{{process.product_sku}}]
                  </div>
                  <div class="caption">
                    {{process.product_name}}
                  </div>
                </td>
                <td v-if='machine.production_unit_m2' class="caption">
                 <strong>{{process.total_mts2| number('0,0[.]00')}}</strong> / {{process.product_mts2*(process.qty_pp===0?1:process.qty_pp)| number('0,0[.]00')}}
                 <v-progress-linear
                    color="light-blue"
                    height="5"
                    :value="process.total_mts2/(process.product_mts2*(process.qty_pp===0?1:process.qty_pp))*100"
                    striped
                  >
                  </v-progress-linear>
                </td>
                <td v-if='machine.production_unit_m2'>
                  <ValidationProvider name="Producción" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model='process.real_mts2'
                      class="quantity"
                      solo
                      dense
                      :min="0"
                      @input="calculateQty(process)"
                      type='number'
                      :error-messages="errors"
                      append-icon="square_foot"
                    />
                  </ValidationProvider>
                </td>
                <td v-if='!machine.production_unit_m2'>
                  <strong>{{process.total_qty| number('0,0[.]00')}}</strong> / {{process.product_qty*(process.qty_pp===0?1:process.qty_pp)+process.merma| number('0,0[.]00')}}
                 <v-progress-linear
                    color="light-blue"
                    height="5"
                    :value="process.total_qty/(process.product_qty*(process.qty_pp===0?1:process.qty_pp)+process.merma)*100"
                    striped
                  >
                  </v-progress-linear>
                </td>
                <td v-if='!machine.production_unit_m2'>
                  <ValidationProvider name="Cantidad" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model='process.real_qty'
                      class="quantity"
                      solo
                      dense
                      :min="0"
                      @input="calculateMts2(process)"
                      type='number'
                      :error-messages="errors"
                      append-icon="view_comfy"
                    />
                  </ValidationProvider>
                </td>
                <td v-if="hour_control">
                    <v-text-field
                      v-model='process.start'
                      solo
                      dense
                      type='datetime-local'
                      hide-details
                    />
                </td>
                <td v-if="hour_control">
                    <v-text-field
                      v-model='process.end'
                      solo
                      dense
                      :min="0"
                      type='datetime-local'
                      hide-details
                    />
                </td>
                <td class="d-flex justify-center">
                  <v-checkbox v-model='process.ask_completed'/>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-row class="py-6">
            <v-col>
              <number-card title="Total Mts2" v-if='machine.production_unit_m2'>
                  {{mts2 | number('0,0[.]00')}}
              </number-card>
              <number-card title="Total Cantidad" v-else>
                  {{qty | number('0,0[.]00')}}
              </number-card>
            </v-col>
            <v-col>
              <ValidationProvider name="Máquina" rules="required" v-slot="{ errors }">
                <v-select
                  label="Equipo"
                  class="px-4"
                  placeholder="Seleccione el Equipo"
                  v-model='equipment'
                  :items="machine.equipments"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :error-messages="errors"
                />
              </ValidationProvider>
              <v-textarea
                v-model='notes'
                outlined
                dense
                label="Notas"
                placeholder="Notas al registro"
                :rows="2"
                class="px-4"
              />
            </v-col>
            
          </v-row>
            <div class="text-end mb-6 px-4">
              <v-btn :disabled="loading" @click="$emit('close')" class="mr-3">Cancelar</v-btn>
              <v-btn type="submit" :loading="loading" color="primary"><v-icon class="mr-2">fas fa-paper-plane</v-icon> Enviar</v-btn>
            </div>
        </v-form>
      </ValidationObserver>
    </v-container>

  </div>
</template>

<script>
import {postProcessOrderComplete} from '@/api/production'
import notifications from '@/utils/notifications'
import NumberCard from "@/components/utils/NumberCard";
export default {
  name:'ProcessComplete',
  props:['processes','machine'],
  components:{
    NumberCard
  },
  data() {
    return {
      equipment:'',
      mts2:0,
      qty:0,
      loading:false,
      notes:'',
    }
  },
  methods:{
    async save(){
      this.loading=true

     

      // Se usa processesToComplete para evitar el circular
      //reference cuando aplico desde la ventada de OT
      const proccessesToComplete = this.processes.map(p=>{
        return {
          id: parseInt(p.process),
          real_mts2: parseFloat(p.real_mts2)||0,
          real_qty:parseFloat(p.real_qty)||0,
          ask_completed: Boolean(p.ask_completed),
          start:p.start?new Date(p.start).toISOString():new Date().toISOString(),
          end: p.end?new Date(p.end).toISOString():new Date().toISOString(),
        }
      })

      if (this.machine.hour_control){
        //Validacion de fechas
        const requiredDates=proccessesToComplete.filter(p=>(p.start==0||p.end==0))
        const invalidDates = proccessesToComplete.filter(p=>(new Date(p.start)>new Date(p.end)))
        if(invalidDates.length>0 || requiredDates.length>0){
          notifications.showError('Error en las fechas de inicio y termino')
          this.loading=false
          return
        }
      }


      await postProcessOrderComplete({
        processes:proccessesToComplete,
        equipment:this.equipment,
        notes:this.notes
      })
      .then((res)=>{
        notifications.showSuccess(res)
        this.loading=false
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch((e)=>{
          notifications.showError('Error Realizando el Registro '+e)
          this.loading=false
      })
    },
    calculateQty(item){
      //Calculo de Cantidad cuando se ingresan valores en Mts2 - Para postcosteo
      const productArea=parseFloat(item.product.width)*parseFloat(item.product.height)*0.0001
      item.real_qty=parseFloat(item.real_mts2)/productArea
      this.suma()
    },
    calculateMts2(item){
      const productArea=parseFloat(item.product.width)*parseFloat(item.product.height)*0.0001
      item.real_mts2=parseFloat(item.real_qty)*productArea
      this.suma()
    },
    suma(){
      this.qty=0
      this.mts2=0
      this.processes.map(proc=>{
        this.qty+=parseFloat(proc.real_qty)
        this.mts2+=parseFloat(proc.real_mts2)
      })
    },
  },
  watch:{ 
    processes(){
      this.mt2=0
      this.qty=0
    }
  },
  computed:{
    hour_control(){
      return this.machine.hour_control
    }
  }
  
}
</script>

<style lang="scss">
.quantity {
    width: 7rem;
    .v-input__control{
      .v-text-field__details{
      min-height: 0px !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
      .v-messages{
      min-height: 0px !important;
      }
    }
    }
  }
</style>
