<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
      <v-col cols="7" class="d-flex align-center">
        <h1 class="text text-h5">Actualizar Orden de Trabajo <span class="titulo text-h4">#{{work_order.id}}</span></h1>
        <div class="d-block ml-6">
          <div class="text caption" title="Creada"> <v-icon class="mr-2" small>fas fa-square-plus</v-icon>{{work_order.created_by.first_name}} {{work_order.created_by.last_name}} - {{work_order.created_at| moment('DD/MM/YYYY h:mm a')}}</div>
          <div v-if="work_order.updated_by" class="text caption" title="Actualizada"><v-icon class="mr-2" small>fas fa-square-pen</v-icon>{{work_order.updated_by.first_name}} {{work_order.updated_by.last_name}} - {{work_order.updated_at| moment('DD/MM/YYYY h:mm a')}}</div>
        </div>
      </v-col>
      <v-col class="text-end">
        <v-btn @click="addingCost=true" text color="primary" class="mr-2"><v-icon class="mr-2">fas fa-plus</v-icon>Agregar Costo</v-btn>
        <v-btn class="mr-2" color="success" @click='save' :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
        <v-btn class="ml-2" text small @click="$router.go(-1)"><v-icon>fas fa-chevron-left</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <v-text-field 
        outlined 
        readonly
        label="Cotización"
        filled
        dense
        v-model="work_order.sales_note"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field 
        outlined 
        readonly
        label="Cliente"
        filled
        dense
        v-model="work_order.customer_name"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field 
        outlined 
        readonly
        label="Proyecto"
        filled
        dense
        v-model="work_order.name"
        />
      </v-col>
      
      <!-- <v-col cols="12">
        <table>
          <tbody>
            <tr>
              <td class="text-right">
                Nota de Venta:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{work_order.sales_note}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Cliente:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{work_order.customer_name}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Proyecto:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{work_order.name}}</div>
              </td>
            </tr>
            <tr v-if="work_order.notes">
              <td class="text-right">
                Notas:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{work_order.notes}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col> -->
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" md="6">
          <v-text-field
          dense
        label='Orden de Compra'
        v-model='work_order.purchase_order'
        outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
        dense
        label='Fecha y Hora de Comprometida'
        @change="changeDeliveryDate"
        :value="formatedDeliveryDate"
        type="datetime-local"
        outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          dense
          :items="deliveryTypeList"
          item-text="name"
          item-value="value"
          v-model='work_order.delivery_type'
          label="Tipo de Despacho"
          outlined
      />  
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          label='Fecha y Hora de Instalación'
          :value="formatedInstallDate"
          @change="changeInstallDate"
          type="datetime-local"
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
      <v-text-field
        dense
        label='Fecha y Hora de Desinstalación'
        :value="formatedUninstallDate"
        @change="changeUninstallDate"
        type="datetime-local"
        outlined
        clearable
      ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col>
        <v-textarea
        dense
        label='Observaciones'
        v-model='work_order.notes'
        rows="4"
        outlined
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col justify="center">
        <div class="py-4">Productos</div>
        <v-data-table
        :headers="productsTableHeaders"
        :items="work_order.products"
        :single-expand="true"
        expanded.sync="expanded"
        expand-icon="fas fa-plus"
        item-key="id"
        class="flex d-block pa-2"
        >
          <template v-slot:item.actions="{item}">
            <div class="d-flex">
              <v-btn text @click="editProduct(item)" :disabled="item.completed"><v-icon small>fas fa-pen</v-icon></v-btn>
              <v-btn mr-2 text @click="deleteProduct(item)" class="error--text" :disabled="item.completed||work_order.products.length<2"><v-icon small>fas fa-trash</v-icon></v-btn>
            </div>
          </template>
          <template v-slot:item.item="{item}">
            {{work_order.products.indexOf(item)+1}}
          </template>
          <template v-slot:item.name="{item}">
            <v-icon v-if='item.change' class="mr-2 red--text" small>fas fa-exclamation-circle</v-icon>
            <span v-if='item.delete' class='to-delete'>{{item.name}}</span>
            <span v-else>{{item.name}}</span>
          </template>
          <template v-slot:item.width="{item}">
            {{item.width | number('0,0.00')}}
          </template>
          <template v-slot:item.height="{item}">
            {{item.height | number('0,0.00')}}
          </template>
          <template v-slot:item.qty="{item}">
            {{item.qty}}
          </template>
          <template v-slot:item.totalMts2="{item}">
            {{item.mts2 | number('0,0.00')}}
          </template>
          <template v-slot:item.progress="{item}">
            <v-progress-linear
                v-model="item.progress"
                color="primary"
                height="25"
              >
              <template v-slot:default="{ value }">
                <strong>{{ Math.floor(value) }}%</strong>
              </template>
            </v-progress-linear>
          </template>
          <template v-slot:item.estimated="{ item }">
            {{item.estimated | moment("DD/MMM/YYYY")}}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-4" :colspan="headers.length">
          <p v-if="item.notes" class="font-italic">Observaciones:{{item.notes}}</p>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Item
                  </th>
                  <th class="text-left">
                    Proceso
                  </th>
                  <th class="text-left">
                    Estimado
                  </th>
                  <th class="text-left">
                    Estatus
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="process in item.process_orders"
                  :key="item.process_orders.indexOf(process)"
                  :class="process.completed? '':'font-weight-black red--text'"
                >
                  <td>{{item.process_orders.indexOf(process)}}</td>
                  <td>{{process.name}}</td>
                  <td>{{process.estimated | moment("DD/MMM/YYYY")}}</td>
                  <td>{{process.completed?'Completado':'Pendiente'}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="work_order.additional_costs.length>0" justify="center" class="mt-4">
      <v-data-table
      :loading="costLoading"
      :headers="additionalCostsHeaders"
      :items="work_order.additional_costs"
      item-key="key"
      class="flex d-block"
      hide-default-footer
      >
      <template v-slot:top>
        <div class="text text-h6 pa-4">Costos Adicionales</div>
      </template>
      <template v-slot:item.price="{item}">
        {{item.price|moneda}}
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn small @click="deleteCost(item)" text class="error--text"><v-icon small>fas fa-trash</v-icon></v-btn>
      </template>
      </v-data-table>
    </v-row>
    <v-dialog v-model="editingProduct" max-width="400">
      <v-card class="">
        <ValidationObserver v-slot="{handleSubmit}">
          <v-form @submit.prevent="handleSubmit(saveProduct)">
            <v-card-title>Editar Producto</v-card-title>
            <v-card-subtitle>Ingrese datos a modificar</v-card-subtitle>
            <v-card-text>
              <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="newProduct.name"
                  label='Nombre' 
                  dense
                  outlined 
                  :error-messages="errors"
                  />
              </ValidationProvider>
              <ValidationProvider name="Ancho" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="newProduct.width"
                  label='Ancho (Centímetros)'  
                  outlined
                  type='number' 
                  dense
                  :error-messages="errors"
                  />
              </ValidationProvider>
              <ValidationProvider name="Alto" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="newProduct.height"
                  label='Alto (Centímetros)'
                  dense
                  outlined 
                  type='number' 
                  :error-messages="errors"
                  />
              </ValidationProvider>
              <ValidationProvider name="Cantidad" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="newProduct.qty"
                  label='Cantidad'   
                  outlined 
                  dense
                  type='number' 
                  :error-messages="errors"
                  />
              </ValidationProvider>
              <v-textarea
                  v-model="newProduct.notes"
                  label='Observaciones'   
                  outlined 
                  rows="4"
                  />
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="success" >Aceptar</v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addingCost" max-width="500">
      <v-card>
        <cost-create-update @close="addingCost=false" @save="addCost" :custom="false" :global="false" :workOrder="true" :cost="newWorkOrderCost" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {updateWorkOrder,getWorkOrder} from '@/api/production'
import notifications from '@/utils/notifications'
import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'
import moment from 'moment'
export default {
  name:'WorkOrderUpdate',
  components:{CostCreateUpdate},
  data() {
    return {
      work_order: {},
       productsTableHeaders:[
      {text:'Acciones',value:'actions',class:"table-header2",align:"center"},
      {text:'Item',value:'item',class:"table-header2",align:'end'},
      {text: 'Descripción', value: 'name' ,class:"table-header2"},
      {text: 'Cantidad', value: 'qty' ,class:"table-header2",align:'end'},
      {text:'Ancho',value:'width',class:"table-header2"},
      {text: 'Alto',value:'height',class:"table-header2"},
      {text: 'Total mts2', value: 'totalMts2' ,class:"table-header2"},
      //{ text: '', value: 'data-table-expand' },
      
    ],
    deliveryTypeList:[
      {
        name:'Despacho',
        value: 0
      },
      {
        name:'Retira el Cliente',
        value:1
      },
      {
        name:'Retira Instalador',
        value:2
      },
      ],
    editDeliveryDate:false,
    editingProduct:false,
    newProduct:{},
    newDeliveryDate:'',
    changes:{
      delivery_date:false,
    },
    loading:false,
    additionalCostsHeaders:[
      { text: 'Acciones', value: 'actions' ,class:"table-header"},
      { text: 'Descripción', value: 'name',class:"table-header" },
      { text: 'Costo', value: 'price' ,class:"table-header"},
    ],
    loading:false,
    addingCost:false,
    newWorkOrderCost:{
      id:-1,
      unit:'',
      name:'',
      price:0,
      min_price:0,
      is_supply:true,
      has_article:false,
      price_update_option:0,
      cost_unit:{name:''}
    },
    //additionalCosts:[],
    costLoading:false,
    }
  },
  computed:{
    formatedDeliveryDate(){
      return moment(this.work_order.delivery_date).format('yyyy-MM-DDTHH:mm')
    },
    formatedInstallDate(){
      return moment(this.work_order.install_date).format('yyyy-MM-DDTHH:mm')
    },
    formatedUninstallDate(){
      return moment(this.work_order.uninstall_date).format('yyyy-MM-DDTHH:mm')
    }
  },
  methods:{
    changeDeliveryDate(val){
      this.work_order.delivery_date = val
    },
    changeInstallDate(val){
      this.work_order.install_date=val
    },
    changeUninstallDate(val){
      this.work_order.uninstall_date=val
    },
    updateDeliveryDate(){
      this.work_order.delivery_date=this.newDeliveryDate
      this.newDeliveryDate=''
      this.changes.delivery_date=true
      this.editDeliveryDate=false
    },
    editProduct(item){
      this.editingProduct=true
      this.newProduct=item
    },
    saveProduct(){
      const mts2=this.newProduct.width*this.newProduct.height*this.newProduct.qty*0.0001
      this.$set(this.newProduct,'change',true)
      this.$set(this.newProduct,'mts2',mts2)
      this.$set(this.newProduct,'estimated','')
      this.$set(this.work_order.products,this.work_order.products.indexOf(this.newProduct),this.newProduct)
      this.editingProduct=false
      this.newProduct={} 
      this.$forceUpdate(); 
    },
    deleteProduct(item){
      item.delete=true
      this.$set(this.work_order.products,this.work_order.products.indexOf(item),item)
      this.$forceUpdate(); 
    },
    async save(){
      this.loading=true
      this.work_order.delivery_date = new Date(this.work_order.delivery_date).toISOString()
      this.work_order.install_date = this.work_order.install_date?new Date(this.work_order.install_date).toISOString():null
      this.work_order.uninstall_date = this.work_order.uninstall_date?new Date(this.work_order.uninstall_date).toISOString():null
      this.work_order.justUpdateCost=false
      await updateWorkOrder(this.work_order)
      .then((data)=>{
        if(data.status){
            notifications.showSuccess(`Orden de trabajo ${data.work_order} actualizada con éxito`)
          }
          else{
            notifications.showWarn(`Orden de trabajo ${data.work_order} actualizada con problemas de stock`)
            if(data.notified){
              notifications.showWarn('Notificación enviada por correo electrónico')
            }
            else{
              notifications.showError('No se pudo enviar notificación por correo electrónico')
            }
        }
        notifications.showInfo('Se recalcularon los valores de la cotización correspondiente')
        this.loading=false
        this.$router.push({name:'WorkOrders'})
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        this.loading=false
      })
    },
    async deleteCost(item){
      const index = this.work_order.additional_costs.indexOf(item);
      this.work_order.additional_costs.splice(index, 1);
    },
    async addCost(item){
      const cost={
        name:item.name,
        price:item.price,
      }
      //Revisa si el objecto es vacío para poder usar la función push. 
      if(Object.keys(this.work_order.additional_costs).length === 0){
        var additional_costs =[]
        additional_costs.push(cost)
        this.work_order.additional_costs = additional_costs
      }
      else{
        this.work_order.additional_costs.push(cost)
      }
    },
  },
  // created(){
  //   if(this.$route.params.workOrder){
  //     Object.assign(this.work_order,this.$route.params.workOrder) 
  //     this.work_order.products.forEach(product => {
  //       product.change=false,
  //       product.delete=false
  //     });
  //   }
  // },
  async created(){
    await getWorkOrder(this.$route.params.id)
    .then(res=>{
      this.work_order=res
      this.work_order.products.forEach(product => {
        product.change=false,
        product.delete=false
      });
    })
    .catch(e=>
    notifications.showError('Error',e)
    )
    .finally(()=>this.loading=false)
  }

}
</script>

<style lang="scss" scoped>
  .to-delete {
    text-decoration:line-through;
    color: red;
  }

</style>