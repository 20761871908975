import moment from 'moment'
import { fetchCompany } from '@/api/parameters'
import numeral, {findFormat} from '@/numeral_locale/numeral_locale.js'
import getInternacional from '../utils/internacional'

moment.locale('es');

//Configuración global de la moneda
var currency_format = '$0,0.00'
var iva_title = 'IGV'
var IVA = 18
var tax_id_label = 'RUT'

export async function pdfCostReport(content) {
    var pdfMake = require("pdfmake/build/pdfmake.js");
    var pdfFonts = require("pdfmake/build/vfs_fonts.js");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    //Trayendo datos de la compañía
    const company = await fetchCompany()

    //Configuración de la moneda
    const code=company.country_code
    numeral.locale(code)
    currency_format = findFormat(code).currency
    iva_title = findFormat(code).iva_title
    IVA = company.tax
    tax_id_label = getInternacional(code).tax_id_label

    //Estableciendo la fecha de emisión
    const quotationDate = moment(new Date()).format('DD-MMM-YYYY');

    //Formato a la dirección del cliente
    const customerFullAddress = content.sales_note_customer.addresses[0].street + ' ' + content.sales_note_customer.addresses[0].number + ' ' + content.sales_note_customer.addresses[0].suite
    const customerComuneAddress = content.sales_note_customer.addresses[0].comune + ' - ' + content.sales_note_customer.addresses[0].region

    //configuración de formado de números 
    const numberFormat = 'de-DE'
   

    //Cálculo de IVA y Total con el IVA
    const totalIVA = content.totals.total * IVA / 100
    const totalconIVA = content.totals.total + totalIVA

    pdfMake.fonts = {
        Poppins: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-SemiBold.ttf"
        },
        RobotoCondensed: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Bold.ttf"
        }
    };

    var dd = {
        pageSize: "LETTER",
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            return currentNode.pageNumbers.length > 1 && currentNode.unbreakable;
        },
        content: [
            { text: 'Fecha: ' + quotationDate, alignment: 'right', fontSize: 10 },
            { text: `Informe de Costos`, fontSize: 20, bold: true, alignment: 'center' },
            { text: `Cotización: ${content.id}`, fontSize: 16, bold: true },
            content.work_order?{ text: `Orden de Trabajo: ${content.work_order}`, fontSize: 16, bold: true }:{},

            // Encabezado centro
            {
                layout: 'noBorders',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['11%', '50%', '20%', '19%'],
                    body: [
                        [
                            { text: 'Cliente:', alignment: 'right' },
                            { text: content.sales_note_customer.name, alignment: 'left', bold: true, },
                            { text: 'Vendedor:', alignment: 'right' },
                            { text: content.sales_note_customer.seller.first_name + ' ' + content.sales_note_customer.seller.last_name, alignment: 'left', bold: true, },
                        ],
                        [
                            { text: tax_id_label+': ', alignment: 'right' },
                            { text: content.sales_note_customer.rut, alignment: 'left', bold: true, },
                            { text: 'Condición de Venta:', alignment: 'right' },
                            { text: content.sales_note_customer.payment < 1 ? 'Contado' : `Pago a ${content.sales_note_customer.payment} días`, alignment: 'left', bold: true, },
                        ],

                        [
                            { text: 'Referencia:', alignment: 'right' },
                            { colSpan: 3, text: content.name, alignment: 'left', bold: true, },
                            '',
                            '',
                        ],
                    ]
                }
            },
            //Tabla de totales
            { text: 'Totales:', fontSize: 16, margin: [0, 15, 0, 0], },
            {
                table: {
                    headerRows: 1,
                    widths: ['*', '16.5%', '16.5%', '16.5%', '16.5%', '16.5%'],
                    body: [
                        [
                            { text: 'Mts2', alignment: 'center' },
                            { text: 'Costo', alignment: 'center' },
                            { text: 'Precio', alignment: 'center' },
                            { text: 'Descuento', alignment: 'center' },
                            { text: 'Total', alignment: 'center' },
                            { text: 'Margen', alignment: 'center' },
                        ],
                        [
                            { text: numeral(content.totals.mts2).format('0,0[.]00'), bold: true, alignment: 'center' },
                            { text: numeral(content.totals.cost).format(currency_format), bold: true, alignment: 'center' },
                            { text: numeral(content.totals.price).format(currency_format), bold: true, alignment: 'center' },
                            { text: `${content.totals.discount}% (${numeral(content.totals.totalDiscount).format(currency_format)})`, bold: true, alignment: 'center' },
                            { text: numeral(content.totals.total).format(currency_format), bold: true, alignment: 'center' },
                            { text: numeral(content.totals.margin).format('0.00%'), bold: true, alignment: 'center' },
                        ],
                    ]
                }
            },
            { text: 'Productos:', fontSize: 16, margin: [0, 15, 0, 0], },
            buildProductsTable(content.details.products),
            content.details.costs.length>0?{ text: 'Costos Adicionales:', fontSize: 16, margin: [0, 15, 0, 0], }:{},
            content.details.costs.length>0?buildAdditionalCostTable(content.details.costs):{},
        ],

        footer: {
            margin: [0, 0, 15, 0],
            columns: [{
                text: "Generado con RunFlow v8.1",
                alignment: "right",
                style: "footer",

            }]
        },
        defaultStyle: {
            font: "RobotoCondensed",
            fontSize: 10,
            color: "#5B5A59"
        },
        styles: {
            h1: {
                font: "Poppins",
                fontSize: 20,
                bold: true,
                alignment: "center",
                color: "#7A3D8A"
            },
            h2: {
                font: "Poppins",
                fontSize: 12,
                bold: true,
                alignment: "left",
                color: "#5B5A59"
            },
            p: {
                font: "Poppins",
                fontSize: 10,
                lineHeight: 1.2,
                color: "#5B5A59"
            },
            reportHeader: {
                font: "Poppins",
                fontSize: 10,
                lineHeight: 1.5,
                color: "#5B5A59"
            },
            footer: {
                font: "Poppins",
                fontSize: 8,
                color: "#33425B"
            },
            table: {
                font: "Poppins",
                fontSize: 9,
                color: "#5B5A59"
            }
        }
    };
    pdfMake.createPdf(dd).open();
}

function buildProductsTable(products) {
    const productsTable = []
    products.forEach(product => {
        product.recipe.forEach(item => {
            item.totalCost = calculateProcessCost(product, item)
        })
        product.product_supplies.forEach(item => {
            item.totalCost = calculateSuppliesCost(product, item)
        })
        productsTable.push(productTableDetail(products.indexOf(product), product))
    })
    return productsTable
}

function productTableDetail(index, product) {
    const header = {
        unbreakable: true,
        layout: {
            hLineWidth: function(i, node) {
                return (i === 0 || i === node.table.body.length) ? 0 : 0;
            },
            vLineWidth: function(i, node) {
                return (i === 0 || i === node.table.widths.length) ? 0 : 0;
            },
            hLineColor: function(i, node) {
                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
            },
            vLineColor: function(i, node) {
                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
            },
            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function(i, node) { return (i === 0) ? 10 : 0; },
            paddingRight: function(i, node) { return (i === node.table.widths.length) ? 10 : 0; },
            paddingTop: function(i, node) { return (i == 0) ? 10 : 0; },
            paddingBottom: function(i, node) { return (i === node.table.body.length) ? 10 : 0; },
            // fillColor: function (rowIndex, node, columnIndex) { return null; }
        },
        margin: [0, 0, 0, 10],
        table: {
            widths: ['12%', '12%', '12%', '12%', '12%', '*', '12%', '12%'],
            body: [
                [
                    { text: index+1, fontSize: 12, bold: true, alignment: 'center' },
                    { text: `${product.name} [${product.sku}]`, fontSize: 14, bold: true, colSpan: 7, alignment: 'left', margin: [0, 0, 0, 5], },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                ],
                [
                    { text: 'Ancho: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: `${numeral(product.width).format('0,0[.]0')} cms`, bold: true, alignment: 'left' },
                    { text: 'Alto: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: `${numeral(product.height).format('0,0[.]0')} cms`, bold: true, alignment: 'left' },
                    { text: 'Cantidad: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: product.qty, bold: true, alignment: 'left' },
                    { text: 'Mts2: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: numeral(product.totalMts2).format('0,0[.]000'), bold: true, alignment: 'left' },
                ],
                [
                    { text: 'Costo Unit.: ', alignment: 'right', margin: [0, 0, 3, 0], },
                    { text: numeral(product.unitCost).format(currency_format), bold: true, alignment: 'left' },
                    { text: 'Costo Tot.: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: numeral(product.totalCost).format(currency_format), bold: true, alignment: 'left' },
                    { text: 'Precio Unit.: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: `${numeral(product.price).format(currency_format)} ${product.sell_unit_m2?'(p. mts2)':'(p. unid)'}`, bold: true, alignment: 'left' },
                    { text: 'Precio Tot.: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: numeral(product.totalPrice).format(currency_format), bold: true, alignment: 'left' },
                ],
                [{ text: 'Procesos:', colSpan: 8, fontSize: 10 }, {}, {}, {}, {}, {}, {}, {}, ],
                [buildProcessTable(product), {}, {}, {}, {}, {}, {}, {}, ],
                [{ text: 'Materiales:', colSpan: 8, fontSize: 10, }, {}, {}, {}, {}, {}, {}, {}, ],
                [buildSuppliesTable(product.product_supplies,product.qty), {}, {}, {}, {}, {}, {}, {}, ],
            ]
        }
    }
    return header
}

function buildSuppliesTable(supplies,product_qty) {
    if (supplies.length == 0) return {}
    else {
        return {
            colSpan: 8,
            layout: {
                fillColor: function(rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0 && rowIndex >= 0) ? null : '#c2efc7';
                }
            },
            table: {
                widths: ['5%', '*', '10%', '10%', '10%', '15%', ],
                headerRows: 1,
                body: buildSuppliesItemsTable(supplies,product_qty)
            }
        }
    }
}

function buildProcessTable(product) {
    const processes = product.recipe
    return {
        colSpan: 8,
        margin: [0, 0, 0, 10],
        layout: {
            fillColor: function(rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0 && rowIndex >= 0) ? null : '#dee5ef';
            }
        },
        table: {
            widths: ['5%', '*', '10%', '10%', '10%','10%','15%', ],
            headerRows: 1,
            body: buildProcessItemsTable(processes,product)
        }
    }
}

function buildProcessItemsTable(processes,product) {
    var tableData = [];
    var body = [];
    //columnas que va a tomar del objeto
    const columns = ['item', 'name', 'price', 'unit_name','qtypp','qtytot', 'total']
    body.push([
        { text: 'Item', bold: true, alignment: 'center' },
        { text: 'Descripción', bold: true, alignment: 'center' },
        { text: 'Costo Unit.', bold: true, alignment: 'center' },
        { text: 'Unidad', bold: true, alignment: 'center' },
        { text: 'Cant. PP', bold: true, alignment: 'center' },
        { text: 'Cant. Tt', bold: true, alignment: 'center' },
        { text: 'Costo Total', bold: true, alignment: 'center' }
    ]);
    console.log('Proceses',processes)
    processes.map(item => {
        const merma=item.merma || 0
        tableData.push({
            item: { text: processes.indexOf(item)+1, alignment: 'center' },
            name: { text: item.name, alignment: 'left' },
            price: { text: `${numeral(item.price).format(currency_format)}`, alignment: 'right' },
            unit_name: { text: item.unit_name, alignment: 'center' },
            qtypp:{text:`${item.qty} ${merma>0?`+${merma}`:''}`,alignment:'right'},
            qtytot:{text:item.totalCost.qtyto,alignment:'right'},
            total: { text: `${item.totalCost.min?'(min)':''} ${numeral(item.totalCost.cost).format(currency_format)}`, alignment: 'right' },
        })
    })

    tableData.forEach(function(row) {
        var dataRow = [];
        columns.forEach(function(column) {
            dataRow.push(row[column]);
        });
        body.push(dataRow);
    });
    return body;
}

function buildSuppliesItemsTable(supplies,product_qty) {
    var tableData = [];
    var body = [];
    //columnas que va a tomar del objeto
    const columns = ['item', 'name', 'price', 'unit_name', 'qty', 'total']
    body.push([
        { text: 'Item', bold: true, alignment: 'center' },
        { text: 'Descripción', bold: true, alignment: 'center' },
        { text: 'Costo Unit.', bold: true, alignment: 'center' },
        { text: 'Unidad', bold: true, alignment: 'center' },
        { text: 'Cantidad', bold: true, alignment: 'center' },
        { text: 'Costo Total', bold: true, alignment: 'center' }
    ]);
    supplies.map(item => {
        item.name=item.has_dimension?item.name+'\n'+`An.: ${item.width} x Alt.:${item.height}`+'\n'+`Cant. Total ${Math.ceil(item.qty*product_qty)}` :item.name
        tableData.push({
            item: { text: supplies.indexOf(item)+1, alignment: 'center' },
            name: { text: item.name, alignment: 'left' },
            price: { text: numeral(item.price).format(currency_format), alignment: 'right' },
            unit_name: { text: item.unit_name, alignment: 'center' },
            qty: { text: item.qty>=1?item.qty:numeral(item.qty).format('0[.]000'), alignment: 'right' },
            total: { text: `${item.totalCost.min?'(min)':''} ${numeral(item.totalCost.cost).format(currency_format)}`, alignment: 'right' }
        })
    })

    tableData.forEach(function(row) {
        var dataRow = [];
        columns.forEach(function(column) {
            dataRow.push(row[column]);
        });
        body.push(dataRow);
    });
    return body;
}


function buildAdditionalCostTable(costs) {

    return {
        colSpan: 8,
        margin: [0, 0, 0, 10],
        unbreakable: true,
        layout: {
            fillColor: function(rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0 && rowIndex >= 0) ? null : '#bbe1fa';
            }
        },
        table: {
            widths: ['12%', '*', '12%', '12%', '12%', '12%', '12%', '12%'],
            headerRows: 1,
            body: buildItemsTableBody(costs)
        }
    }
}


function buildItemsTableBody(data) {
    
    var body = [];
    var tableData = [];
    const numberFormat = 'de-DE'

    //columnas que va a tomar del objeto
    const columns = ['item', 'name', 'qty', 'unit_name', 'unit_cost', 'total_cost', 'unit_price', 'total_price']

    //Creando el Header de la Table
    body.push(['Item', 'Descripción', 'Cantidad', 'Unidad.', 'Costo Unit.', 'Costo Tot.', 'Precio Unit.', 'Precio Tot.']);

    //preparando la data para crear la tabla
    //Incluimos los productos
    data.map(item => {
        tableData.push({
            item: { text: data.indexOf(item)+1, alignment: 'center' },
            name: { text: item.name, alignment: 'left' },
            qty: { text: numeral(item.qty).format('0,0'), alignment: 'right' },
            unit_name: { text: item.cost_unit, alignment: 'center' },
            unit_cost: { text: numeral(item.price).format(currency_format), alignment: 'right' },
            total_cost: { text: numeral(item.totalCost).format(currency_format), alignment: 'right' },
            unit_price: { text: numeral(item.sell_price).format(currency_format), alignment: 'right' },
            total_price: { text: numeral(item.totalPrice).format(currency_format), alignment: 'right' },
        })
    })


    tableData.forEach(function(row) {
        var dataRow = [];
        columns.forEach(function(column) {
            dataRow.push(row[column]);
        });
        body.push(dataRow);
    });
    return body;
}

function calculateSuppliesCost(product, item) {
    const mts2 = product.totalMts2
    const qty = product.qty
    if (item.unit == 1) {
        return {
            cost: item.min_price > (item.price * item.qty * mts2) ? item.min_price : (item.price * mts2 * item.qty),
            min: item.min_price > (item.price * item.qty * mts2) ? true : false
        }
    } else if (item.unit == 2) {
        return {
            cost: item.min_price > (item.price * item.qty * qty) ? item.min_price : (item.price * item.qty * qty),
            min: item.min_price > (item.price * item.qty * qty) ? true : false
        }
    } else return { cost: item.price * item.qty, min: false }
}

function calculateProcessCost(product, item) {
    const mts2 = product.totalMts2
    const qty = product.qty
    if (item.unit == 1) {
        return {
            cost: item.min_price > (item.price *item.qty* mts2) ? item.min_price : (item.price *item.qty* mts2),
            min: item.min_price > (item.price *item.qty* mts2) ? true : false,
            qtyto:numeral(item.qty*mts2).format('0,0.00')
        }
    } else if (item.unit == 2) {
        const merma=item.merma||0
        return {
            cost: item.min_price > (item.price *(item.qty* qty+merma)) ? item.min_price : (item.price *(item.qty* qty+merma)),
            min: item.min_price > (item.price *(item.qty* qty+merma)) ? true : false,
            qtyto:numeral(item.qty*qty + merma).format('0,0[.]00')
        }
    } else return { cost: item.price, min: false,  qtyto:numeral(item.qty).format('0,0.00') }
}