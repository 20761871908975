<template>
  <v-container class="">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Asignar Equipo</v-toolbar-title>
    </v-toolbar>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form class="mt-2" @submit.prevent="handleSubmit(save)">
        <v-simple-table class="px-4">
          <thead>
            <tr>
              <th class="table-header-2">
                OT
              </th>
              <th class="table-header-2">
                Producto
              </th>
              <th class="table-header-2">
                Proceso
              </th>
              <th v-if='machine.production_unit_m2' class="table-header-2">
                Total Mts<sup>2</sup>
              </th>
              
            </tr>
          </thead>
          <tbody>
            <tr v-for="process in processes" :key="process.id" class="py-2">
              <td>
                <div>
                  {{process.work_order}}
                </div>
                <div>
                  {{ process.customer_name }}
                </div>
              </td>
              
              <td>
                <div v-if='process.product_sku' class="text text-caption">
                 [{{process.product_sku}}]
                </div>
                <div>
                  <!-- {{process.product_name}} -->
                </div>
              </td>
              <td>
                {{ process.name }}
              </td>
              <td v-if='machine.production_unit_m2'>
               <strong>{{process.total_mts2}}</strong> / {{process.product_mts2}}
               <v-progress-linear
                  color="light-blue"
                  height="5"
                  :value="process.total_mts2/process.product_mts2*100"
                  striped
                >
                </v-progress-linear>
              </td>
             
              <td v-if='!machine.production_unit_m2'>
                <strong>{{process.total_qty}}</strong> / {{process.product_qty}}
               <v-progress-linear
                  color="light-blue"
                  height="5"
                  :value="process.total_qty/process.product_qty*100"
                  striped
                >
                </v-progress-linear>
              </td>
            </tr>
           
          </tbody>
        </v-simple-table>
        <ValidationProvider name="Máquina" rules="required" v-slot="{ errors }">
          <v-select
            label="Equipo"
            class="px-4 mt-3"
            placeholder="Seleccione el Equipo"
            v-model='equipment'
            :items="equipmentList"
            item-text="name"
            item-value="id"
            outlined
            :error-messages="errors"
          />
        </ValidationProvider>
          <div class="text-center mb-6">
            <v-btn type="submit" :loading="loading" x-large><v-icon class="mr-2">fas fa-paper-plane</v-icon> Asignar</v-btn>
          </div>
      </v-form>
    </ValidationObserver>

  </v-container>
</template>

<script>
import {asignEquipment} from '@/api/production'
import notifications from '@/utils/notifications'
export default {
  name:'AsignEquipment',
  props:['processes','machine'],
  data() {
    return {
      equipment:'',
      loading:false,
    }
  },
  methods:{
    async save(){
      this.loading=true
      // Se usa processesToComplete para evitar el circular
      //reference cuando aplico desde la ventada de OT
      await asignEquipment({
        processes:this.processes,
        equipment:this.equipment,
      })
      .then((res)=>{
        notifications.showSuccess(res)
        this.loading=false
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch((e)=>{
          notifications.showError('Error Realizando el Registro '+e)
          this.loading=false
      })
    },
  },
  computed:{
    equipmentList(){
      return this.machine.equipments.filter(e=>e.enabled)
    }},
  watch:{ 
    processes(){
      this.mt2=0
      this.qty=0
    }
    }
  
}
</script>


