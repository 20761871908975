<template>
  <v-container>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(submit)">
        <v-row justify="end">
          <v-btn :loading='loading' color="success" type="submit"><v-icon class="mr-2">fas
              fa-sd-card</v-icon>Guardar</v-btn>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-card>
          <v-card-title>
            Empresa
          </v-card-title>
          <v-card-subtitle>
            Información general de la empresa para documentos
          </v-card-subtitle>
          <v-card-text>
            <v-text-field dense :label='internacional.tax_id_label' v-model='rut' outlined :disabled=true></v-text-field>
            <ValidationProvider name="Razón Social" rules="required" v-slot="{ errors }">
              <v-text-field dense label='Razón Social' v-model='name' outlined :error-messages="errors"></v-text-field>
            </ValidationProvider>
            <v-text-field dense label='Giro' v-model='giro' outlined></v-text-field>
            <v-text-field dense label='Nombre Fantasía' v-model='fantasy' outlined></v-text-field>
            <v-text-field dense label='Dirección' v-model='address' outlined></v-text-field>
            <v-text-field dense label='Teléfono' v-model='phone' outlined></v-text-field>
            <v-text-field dense label='Correo Electrónico' v-model='email' outlined></v-text-field>
            <v-text-field dense label='Página Web' v-model='webpage' outlined></v-text-field>
            <div class="flex d-inline-flex">
              <v-file-input v-model=logoFile accept='image/*' label='logo' prepend-icon="fas fa-cloud-upload-alt"
                outlined show-size hint="Agregue el logo de su empresa" @change="onFileChange"
                persistent-hint></v-file-input>
              <v-img class="ml-4" max-height="150" max-width="250" :src='logoFile ? url : logo'></v-img>
            </div>
          </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
          <v-card-title>
            Orden de Compra
          </v-card-title>
          <v-card-subtitle>
            Parámetros por defecto para orden de compra
          </v-card-subtitle>
          <v-card-text>
            <v-text-field dense type="number" :min="0" label='Límite Aprovación de PO' v-model='po_approve_limit'
              outlined></v-text-field>
            <v-text-field dense label='Moneda por Defecto Orden de compra' v-model='default_currency'
              outlined></v-text-field>
            <v-text-field dense type="number" :min="0"
              :label="internacional.purchase_tax_label + 'por defecto Orden de compra'" v-model='tax'
              outlined></v-text-field>
          </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
          <v-card-title>Servidor de Correos</v-card-title>
          <v-card-subtitle>
            Configuración de servidor de correo para envío de notificaciones
          </v-card-subtitle>
          <v-card-text>
            <v-text-field @change="()=>email_tested=false" dense label="Servidor" v-model='email_host' outlined />
            <v-text-field @change="()=>email_tested=false" dense label="Usuario" v-model='email_user' outlined />
            <v-text-field @change="()=>email_tested=false" dense label="Password" v-model='email_password' outlined />
            <v-text-field @change="()=>email_tested=false" dense label="Puerto" v-model='email_port' outlined />
            <v-text-field @change="()=>email_tested=false" dense label="Correo de envío" v-model='email_from' outlined />
            <v-select 
            @change="()=>email_tested=false" 
            label="Encriptación"
            outlined
            dense
            item-text="text"
            item-value="value"
            v-model="email_smtp_encryption"
            :items="[{text:'SSL',value:'ssl'},{text:'TLS',value:'tls'}]">
            </v-select>
            <email-test 
            v-model="email_tested" 
            :email_host="email_host"
            :email_user="email_user"
            :email_password="email_password"
            :email_port="email_port"
            :email_smtp_encryption="email_smtp_encryption"
            :email_from="email_from"
            />
          </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
          <v-card-title>
            Cotización
          </v-card-title>
          <v-card-subtitle>
            Parámetros por defecto para cotización
          </v-card-subtitle>
          <v-card-text>
            <v-textarea v-model="fixed_text_quotation" outlined label="Texto en documento de cotización" dense />
          </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
          <v-card-title>
            Producción
          </v-card-title>
          <v-card-subtitle>
            Configuración de producción
          </v-card-subtitle>
          <v-card-text>
            <v-checkbox v-model='preproduction_active' label="Usar Preproducción" dense />
            <v-checkbox v-model='complete_ot_without_logistics' label="Completar OT sin Productos Logistica" dense />
            <v-checkbox v-model='load_planner_on_initial' label="Cargar Planificador al iniciar" dense />
            <v-checkbox v-model='stock_validation_active' label="Validación de Stock" dense />
            <v-textarea v-show="stock_validation_active" v-model="stock_validation_email_list" outlined
              label="Lista de notificación de para validación de stock" hint="Separar correos con coma (,)" dense />
          </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
          <v-card-title>
            Dashboard
          </v-card-title>
          <v-card-subtitle>
            Configuración de página inicial
          </v-card-subtitle>
          <v-card-text>
            <v-checkbox v-model='dashboard_card_quoted' label="Dashboard mostrar cotizado" dense />
            <v-checkbox v-model='dashboard_card_confirmed' label="Mostrar confirmado" dense />
            <v-checkbox v-model='dashboard_card_confirmed_finished' label="Mostrar finalizado" dense />
            <v-checkbox v-model='dashboard_card_confirmed_finished_not_invoiced' label="Mostrar finalizado no facturado"
              dense />
            <v-checkbox v-model='dashboard_card_invoiced' label="Mostrar facturado" dense />
            <v-checkbox v-model='dashboard_card_paid' label="Mostrar pagado" dense />
          </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card>
          <v-card-title>
            Integración
          </v-card-title>
          <v-card-subtitle>
            Configuración de integración con otros sistemas
          </v-card-subtitle>
          <v-card-text>
            <v-checkbox v-model='factronica_integration_activated' label="Activar Factrónica" dense />
          </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
    <v-btn color="" text xs @click="cleanWorkOrders">Limpiar Error OT</v-btn>
  </v-container>
</template>

<script>
import {fetchCompany,updateCompany} from '@/api/parameters'
import {cleanWorkOrders} from '@/api/production'
import notifications from '@/utils/notifications'
import { internacional } from '../../utils/internacional';
import EmailTest from './EmailTest.vue'
export default {
  name:'Company',
  components:{EmailTest},
  data:()=>({
    rut:'',
    name:'',
    giro:'',
    fantasy:'',
    address:'',
    phone:'',
    email:'',
    webpage:'',
    logo:'',
    logoFile:'',
    url:'',
    po_approve_limit:0,
    default_currency:'',
    tax:0,
    preproduction_active:false,
    dashboard_card_quoted:true,
    dashboard_card_confirmed:true,
    dashboard_card_confirmed_finished:true,
    dashboard_card_invoiced:true,
    dashboard_card_paid:true,
    dashboard_card_confirmed_finished_not_invoiced:false,
    complete_ot_without_logistics:false,
    fixed_text_quotation:'',
    factronica_integration_activated:false,
    load_planner_on_initial:false,
    stock_validation_active:false,
    stock_validation_email_list:'',
    email_host:'',
    email_user:'',
    email_password:'',
    email_port:'',
    email_smtp_encryption:'tls',
    email_tested:false,
    email_from:'',
    loading:false
  }),
  computed:{
    internacional(){
      return this.$store.getters.getInternational
    }
  },
  methods:{
    async cleanWorkOrders(){
      await cleanWorkOrders()
      .then(()=>{
        notifications.showSuccess('OTs Limpiadas')
      })
      .catch((e)=>{
        notifications.showError('Error: '+e)
      })
    },
    async submit(){
      this.loading=true
      var formData = new FormData()
      formData.append('rut',this.rut)
      formData.append('name',this.name)
      formData.append('fantasy',this.fantasy)
      formData.append('giro',this.giro)
      formData.append('address',this.address)
      formData.append('phone',this.phone)
      formData.append('email',this.email)
      formData.append('webpage',this.webpage)
      formData.append('stocks_po_approve_limit',this.po_approve_limit)
      formData.append('default_currency',this.default_currency)
      formData.append('tax',this.tax)
      formData.append('preproduction_active',this.preproduction_active)
      formData.append('dashboard_card_quoted',this.dashboard_card_quoted)
      formData.append('dashboard_card_confirmed',this.dashboard_card_confirmed)
      formData.append('dashboard_card_confirmed_finished',this.dashboard_card_confirmed_finished)
      formData.append('dashboard_card_invoiced',this.dashboard_card_invoiced)
      formData.append('dashboard_card_paid',this.dashboard_card_paid)
      formData.append('dashboard_card_confirmed_finished_not_invoiced',this.dashboard_card_confirmed_finished_not_invoiced)
      formData.append('complete_ot_without_logistics',this.complete_ot_without_logistics)
      formData.append('fixed_text_quotation',this.fixed_text_quotation)
      formData.append('factronica_integration_activated',this.factronica_integration_activated)
      formData.append('load_planner_on_initial',this.load_planner_on_initial)
      formData.append('stock_validation_active',this.stock_validation_active)
      formData.append('stock_validation_email_list',this.stock_validation_email_list)
      formData.append('email_host', this.email_host.trim().replace(/\s+/g, ''))
      formData.append('email_user',this.email_user.trim().replace(/\s+/g, ''))
      formData.append('email_password',this.email_password)
      formData.append('email_port',this.email_port.trim().replace(/\s+/g, ''))
      formData.append('email_smtp_encryption',this.email_smtp_encryption)
      formData.append('email_from',this.email_from.trim().replace(/\s+/g, ''))
      if(this.logoFile!='') formData.append('logo',this.logoFile)
      await updateCompany(this.rut,formData)
      .then(()=>{
        notifications.showSuccess('Configuración actualizada')
        this.$store.dispatch('getParameters')
        this.loading=false
      })
      .catch((e)=>{
        this.loading=false
        notifications.showError('Error: '+e)
      })
    },
    onFileChange: function(e) {
    //const file = e.target.files[0];
     if(this.logoFile!='') this.url = URL.createObjectURL(this.logoFile);
     else this.url = ''
    },
  },
  async created(){
    await fetchCompany()
    .then((res)=>{
      if(res!={}){
        console.log(res)
        this.rut = res.rut
        this.old_rut=res.rut
        this.name = res.name
        this.fantasy = res.fantasy
        this.giro=res.giro
        this.address=res.address
        this.phone=res.phone
        this.email=res.email
        this.webpage=res.webpage
        this.logo=res.logo
        this.po_approve_limit=res.stocks_po_approve_limit
        this.default_currency=res.default_currency
        this.tax=res.tax
        this.preproduction_active=res.preproduction_active
        this.dashboard_card_quoted=res.dashboard_card_quoted
        this.dashboard_card_confirmed=res.dashboard_card_confirmed
        this.dashboard_card_confirmed_finished=res.dashboard_card_confirmed_finished
        this.dashboard_card_invoiced=res.dashboard_card_invoiced
        this.dashboard_card_paid=res.dashboard_card_paid
        this.dashboard_card_confirmed_finished_not_invoiced=res.dashboard_card_confirmed_finished_not_invoiced
        this.complete_ot_without_logistics=res.complete_ot_without_logistics
        this.fixed_text_quotation=res.fixed_text_quotation
        this.factronica_integration_activated=res.factronica_integration_activated
        this.load_planner_on_initial=res.load_planner_on_initial
        this.stock_validation_active=res.stock_validation_active
        this.stock_validation_email_list=res.stock_validation_email_list
        this.email_host=res.email_host
        this.email_user=res.email_user
        this.email_password=res.email_password
        this.email_port=res.email_port
        this.email_smtp_encryption=res.email_smtp_encryption
        this.email_from=res.email_from
      }
    })
  }
}
</script>