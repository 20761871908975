<template>
<v-container>
  <h3 v-if="process.work_order">{{process.work_order}} - {{process.name}}</h3>
  <h4 v-if="process.product">[{{process.product_sku}}] {{process.product_name}}</h4>
  <div v-if="loading" class="text text-center pa-4">Cargando datos...</div>
  <div v-if="bitacora.length>0 && !loading">
    <v-simple-table dark>
      <thead class="white--text">
        <tr>
          <th>
            Fecha
          </th>
          <th>
            Usuario
          </th>
          <th v-if="process.unit_m2">
            Mts<sup>2</sup>
          </th>
          <th v-else>
            Cantidad
          </th>
          <th>
            Equipo
          </th>
        </tr>
      </thead>
      <tbody class="white--text">
        <tr v-for='item in bitacora' :key='item.id'>
          <td>
            {{item.created_at | moment("D/MM/YYYY")}}
          </td>
          <td>
            {{item.created_by}}
          </td>
          <td v-if="process.unit_m2">
            {{item.mts2}}
          </td>
          <td v-else>
            {{item.qty}}
          </td>
          <td>
            {{item.equipment_name}}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <h3 v-if="process.unit_m2" class="my-2">
      Total Mts<sup>2</sup>: <strong>{{totalMts2}}</strong>
    </h3>
    <h3 v-else class="my-2">
      Total Cantidad: <strong>{{totalQty}}</strong>
    </h3>
  </div>
  <h3 v-else-if="!loading" class="text text-center pa-4 dark">
    No existen registros
  </h3>
</v-container>
</template>

<script>
import {fetchBitacora} from '@/api/production'
export default {
  name:'BitacoraDetailsPlanner',
  props:['process'],
  data(){
    return {
      bitacora:[],
      loading:true
    }
  },
  computed:{
    totalMts2(){
      var total=0
      this.bitacora.map(r => {
        total+=parseFloat(r.mts2)
      })
      return total
    },
    totalQty(){
      var total=0
      this.bitacora.map(r => {
        total+=parseFloat(r.qty)
      })
      return total
    }
  },
  methods:{
    async getBitacoraData(){
      const query = `process_order=${this.process.process}&page=1&page_size=100`
      await fetchBitacora(query).then(response => {
        console.log(response)
        this.bitacora = response.results
      }).finally(()=>{
        this.loading=false
      })
    }
  },
  created(){
    if(this.process.id){
      this.getBitacoraData()
    }
  }
}
</script>

<style>

</style>