<template>
  <div class="d-flex justify-end" v-if="$can('create', 'Address')">
    <v-btn v-if="action=='create'" small @click="showDialog=true" >
      Agregar Dirección
      <v-icon class="ml-2">fas fa-plus</v-icon>
    </v-btn>
    <v-btn icon small v-if="action=='edit'" @click="actionEdit">
      <v-icon small>fas fa-edit</v-icon>
    </v-btn>
    <v-dialog v-model="showDialog" max-width="600">
      <v-card :loading="loading">
        <v-card-title>
          <span v-if="action=='create'">Crear Dirección</span>
          <span v-if="action=='edit'">Editar Dirección</span>
          
        </v-card-title>
        <v-card-subtitle>
          <span v-if="action=='create'">Llene el formulario con los datos de la dirección</span>
          <span v-if="action=='edit'">Modifique los datos de la dirección</span>
        </v-card-subtitle>
        <v-card-text>
          <ValidationObserver v-slot="{handleSubmit}">
            <v-form @submit.prevent="handleSubmit(submit)">
              <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="name"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Región" rules="required" v-slot="{ errors }">
                <!-- <v-select
                  dense
                  v-model="region"
                  :items="regions"
                  item-text="name"
                  item-value="name"
                  label="Region"
                  placeholder="Seleccione una Región"
                  return-object
                  single-line
                  outlined
                  :error-messages="errors"
                /> -->
                <v-text-field
                  outlined
                  dense
                  :label="international.region_label"
                  :placeholder="international.region_label"
                  v-model="region"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Región" rules="required" v-slot="{ errors }">
                <!-- <v-select
                  dense
                  :items="region.communes"
                  item-text="name"
                  item-value="name"
                  v-model="comune"
                  placeholder='Seleccione una Comuna'
                  label="Comuna"
                  outlined
                  single-line
                  :error-messages="errors"
                  /> -->
                    
                  <v-text-field
                  outlined
                  dense
                  :label="international.comune_label"
                  :placeholder="international.comune_label"
                  v-model="comune"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Ciudad" rules="required" v-slot="{ errors }">
                <v-text-field
                dense
                outlined
                label="Ciudad"
                placeholder="Ciudad"
                v-model="city"
                clearable
                :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Calle" rules="required" v-slot="{ errors }">
                <v-text-field
                dense
                outlined
                label="Calle"
                placeholder="Calle"
                v-model="street"
                clearable
                :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Número" rules="required" v-slot="{ errors }">
                <v-text-field
                outlined
                dense
                placeholder="Escriba la numeración"
                label="Número"
                v-model="number"
                clearable
                :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <v-text-field
              outlined
              label="Adicional"
              dense
              placeholder="Piso, Depto, Oficina"
              v-model="suite"
              clearable
              
              ></v-text-field>
              <v-btn v-if="action!='create'" small color="error" class="mb-4" @click="showConfirmDelete=true">Eliminar<v-icon class="ml-2" small>fas fa-trash</v-icon></v-btn>
              <div class="d-flex justify-end">
                <v-btn text @click="cancel()">
                  Cancelar
                </v-btn>
                <v-btn type="submit" color="success" >
                  <v-icon class="mr-2">fas fa-sd-card</v-icon>
                  Guardar
                </v-btn>
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirm
      v-model="showConfirmDelete" 
      :message="`¿Confirma eliminar esta dirección? Esta operación no se puede deshacer.`"
      @ok="deleteAddress"
      @cancel="cancel"/>
  </div>
</template>

<script>
import notifications from '@/utils/notifications'
import regions_comunes from '@/utils/regions_comunes.json'
import {createAddress,deleteAddress,updateAddress} from '@/api/customers'
export default {
  name: 'AddressCreateUpdate2',
  props:{
    customerId:{  // Propiedad para el id del cliente
      type:Number,
      required:true,
      default:null
    },
    value:{
      type:Object,
      default:null
    },
    action:{
      type:String,
      default:'create'
    },
    small:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    international(){
      return this.$store.getters.getInternational;
    }
  },
  data:()=>({
    regions: regions_comunes.regions,
    name:'',
    region:'',
    comune:'',
    city:'',
    street:'',
    number:'',
    suite:'',
    showDialog:false,
    showConfirmDelete:false,
    loading:false
  }),
  methods:{
    submit(){
      this.loading=true;
      if(this.action=='create'){
        this.addAddress();
      }else if(this.action=='edit'){
        this.editAddress();
              
      }else return
    },
    async addAddress(){
      console.log('Creando direccion');
      //Verifico si existe un cliente para asignarle el contacto
      if(!this.customerId){
        notifications.showError('Error','Debe seleccionar un cliente para asignar la dirección');
        this.loading=false;
      }
      //Creo la dirección
      let address={
        name:this.name,
        region:this.region,
        comune:this.comune,
        city:this.city,
        street:this.street,
        number:this.number,
        suite:this.suite,
        default:false,
        customer:this.customerId
      }
      await createAddress(address)
      .then(()=>{
        notifications.showSuccess('Dirección creada','La dirección ha sido creado exitosamente');
        this.$emit('created',address);
        this.cancel();
      })
      .catch(()=>{
        notifications.showError('Error','Ha ocurrido un error al crear la dirección');
      })
      .finally(()=>{
        this.loading=false;
      });
    },
    async editAddress(){
      console.log('Editando dirección');
      let address={
        id:this.value.id,
        name:this.name,
        region:this.region,
        comune:this.comune,
        city:this.city,
        street:this.street,
        number:this.number,
        suite:this.suite,
        default:false,
      }
      await updateAddress(address)
      .then(()=>{
        notifications.showSuccess('Dirección actualizada','La dirección ha sido actualizado exitosamente');
        this.$emit('updated',address);
      })
      .catch(()=>{
        notifications.showError('Error','Ha ocurrido un error al actualizar la dirección');
      })
      .finally(()=>{
        this.cancel();
      });
    },
    actionEdit(){
      this.name=this.value.name;
      //const region=regions_comunes.regions.find(r=>r.name==this.value.region);
      this.region=this.value.region;
      // const communes=region?region.communes:[];
      // this.region={
      //   name:this.value.region,
      //   communes:communes
      // };
      this.comune=this.value.comune;
      this.city=this.value.city;
      this.street=this.value.street;
      this.number=this.value.number;
      this.suite=this.value.suite;
      this.showDialog=true;
    },
    async deleteAddress(){
      this.loading=true;
      await deleteAddress({id:this.value.id})
      .then(()=>{
        notifications.showSuccess('Dirección eliminada','La dirección ha sido eliminada exitosamente');
        this.$emit('deleted',this.value);
      })
      .catch(()=>{
        notifications.showError('Error','Ha ocurrido un error al eliminar la dirección');
      })
      .finally(()=>{
        this.cancel();
      });

    },
    cancel(){
      this.showDialog=false;
      this.showConfirmDelete=false;
      this.name='';
      this.region='';
      this.comune='';
      this.street='';
      this.number='';
      this.suite='';
      this.city='';
      this.loading=false;
    }
  },
  mounted(){
    
  }

  // Your component's properties, methods, etc. go here
}
</script>

<style scoped>
/* Your component's styles go here */
</style>