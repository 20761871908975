var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"dte__date_emited","sort-desc":true,"items-per-page":"5","loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right'
              }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-2",attrs:{"justify":"end"}},[_c('export-product-invoice-detail',{attrs:{"product":_vm.product,"filter":_vm.filter,"options":_vm.options,"disabled":_vm.loading}})],1)]},proxy:true},{key:"item.dte__document_id",fn:function(ref){
              var item = ref.item;
return [(item.tipo==60)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":"error"}},[_vm._v(" NC ")]):_vm._e(),_vm._v(" "+_vm._s(item.dte__document_id)+" ")]}},{key:"item.total",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.total))+" ")]}},{key:"item.avg_price",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.avg_price))+" ")]}},{key:"item.qty",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.avg_price,'0.0'))+" ")]}},{key:"item.dte__date_emited",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.dte__date_emited,'DD/MM/YYYY'))+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"mb-2 justify-space-around"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }