<template>
  <v-container>
     <div v-show="!stand_alone">
       <v-row class="mb-4" justify="space-between" align-content="center">
        <h1 class="text text-h4 titulo">Bitacora de Consumo por OT</h1>
        <export-consume-o-t-bitacora :filter="filter" :options="options" :disabled="loading"/>
       </v-row>
      <v-row class="mb-4" justify="end">
        <v-col align-self="center" cols="12" md="6">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_after' 
          class="mr-4" 
          outlined 
          clearable 
          type="date" 
          label="Creada Desde:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_before' 
          clearable 
          outlined 
          type="date" 
          label="Creada Hasta:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
            <v-select
              v-model="filter.family"
              :items="families"
              :loading="familiesLoading"
              item-text="name"
              item-value="id"
              label="Grupo de artículos"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              hide-details
              @change="()=>{options.page=1;fetch()}"
            />
        </v-col>
        <v-col justify-end cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            @keyup="searchTimeOut"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
     </div>
     <div>
      <v-data-table
        class="pa-4"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
         @update:options="fetch"
      >
        <template v-slot:top>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{item.created_at | moment("DD/MM/YYYY")}}</div>
        </template>
        <template v-slot:item.transaction="{ item }">
          <div class="caption">{{item.transaction}}</div>
        </template>
        <template v-slot:item.workorder="{item}">
          <show-work-order :work_order_id="item.workorder.id">
            <div class="caption mb-1">{{item.workorder.customer_name}}</div>
            {{item.workorder.id}}
          </show-work-order>
        </template>
        <template v-slot:item.article__sku="{item}">
          <div class="caption">[{{item.article.sku}}]</div>
          <div class="caption">{{item.article.name}}</div>
        </template>
        <template v-slot:item.articlePrice="{item}">
          {{item.articlePrice | moneda}}
        </template>
        <template v-slot:item.convertedPrice="{item}">
          <div class="caption">[{{item.unitConsume_factor|number('0.0000') }}]</div>
          <div>{{item.convertedPrice | moneda}}</div>
        </template>
        <template v-slot:item.qty="{ item }">
          {{item.qty |number('0,0')}}
        </template>
        <template v-slot:item.total="{ item }">
           {{item.total |moneda}}
        </template>
        <template v-slot:item.process_order__family__name="{ item }">
          {{item.family_name}}
        </template>
        <template v-slot:item.equipment__name="{ item }">
          {{item.equipment_name}}
        </template>
        <template v-slot:item.production="{ item }">
          {{(item.family_production_unit_m2?item.mts2:item.qty)|number('0,0')}} <v-icon small>{{item.family_production_unit_m2?'square_foot':'view_comfy'}}</v-icon>
        </template>
        <template v-slot:item.created_by__first_name="{ item }">
          <div class="caption">{{item.created_by_full_name}}</div>
        </template>
  </v-data-table>
  </div>
  </v-container>
</template>

<script>
import {fetchFamilies} from '@/api/stocks'
import {fetchConsumtionBitacoraByOT} from '@/api/production'

import ExportConsumeOTBitacora from '@/components/reports/ExportConsumeOTBitacora'
import notifications from '@/utils/notifications'
import moment from 'moment'
export default {
  name:'ConsumeOTBitacora',
  components:{ExportConsumeOTBitacora},
  props:{
    workorder_id: {
      type: Number,
      default: 0
    },
    stand_alone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading:false,
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        family:'',
        },
      options:{},
      totalItems:0,
      display:false,
      search:'',
      items:[],
      families:[],
      familiesLoading:false,
      headers:[
          {text: 'Creada',align: 'start',value: 'created_at',class: 'table-header2',divider:true,width: '2%'},
          {text:'Transac.',value:'transaction',class:'table-header2',divider:true,width:'8%'},
          {text: 'Cliente | OT',align: 'start',value: 'workorder',class: 'table-header2',divider:true,width: '20%'},
          {text: 'Artículo',align: 'start',value: 'article__sku',class: 'table-header2',width: '20%'},
          {text: 'Unid. Compra',align: 'start',value: 'unit',class: 'table-header2',sortable:false},
          {text: 'Precio Prom.',align: 'end',value: 'articlePrice',class: 'articlePrice',sortable:false},
          {text: 'Unid. Consumo',align: 'start',value: 'unitConsume',class: 'table-header2',sortable:false,divider:true,},
           {text: 'Precio Conv.',align: 'end',value: 'convertedPrice',class: 'table-header2',sortable:false},
          {text:'Cant.',align:'end',value:'qty',class:'table-header2',sortable:false},
          {text:'Total',align:'end',value:'total',class:'table-header2',sortable:true},
          {text:'Usuario',align:'start',value:'created_by__first_name',class:'table-header2',sortable:true},
          
        ]
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')

      if(!this.filter.family) this.filter.family=''
     
      
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      //const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&article__family=${this.filter.family}`
      let query=''
      if(this.stand_alone&&this.workorder_id){
        query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&workorder=${this.workorder_id}`
      }
      else {
        query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&created_after=${created_after}&created_before=${created_before}&article__family=${this.filter.family}`
      }
      await fetchConsumtionBitacoraByOT(query)
      .then((data)=>{
        this.totalItems=data.count
        this.items = data.results
      })
      .catch((err)=>notifications.showError(err))
      .finally(()=>this.loading=false)
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
  async mounted(){
    await this.fetch()
    this.familiesLoading=true
    this.families=await fetchFamilies()
    this.familiesLoading=false
  },
  computed:{
      equipments(){
        return this.filter.family?this.filter.family.equipments:[]
      }
    }
}
</script>
