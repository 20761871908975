<template>
  <v-container>
    <h3 v-if="process.work_order">{{process.work_order}} - {{process.name}}</h3>
    <h4 v-if="process.product">[{{process.product.library_product.sku}}] {{process.product.name}}</h4>
    <div v-if="process.bitacora.length>0">
      <v-simple-table dark>
        <thead class="white--text">
          <tr>
            <th>
              Fecha
            </th>
            <th>
              Usuario
            </th>
            <th v-if="process.machine.production_unit_m2">
              Mts<sup>2</sup>
            </th>
            <th v-else>
              Cantidad
            </th>
            <th>
              Equipo
            </th>
          </tr>
        </thead>
        <tbody class="white--text">
          <tr v-for='item in process.bitacora' :key='item.id'>
            <td>
              {{item.created_at | moment("D/MM/YYYY")}}
            </td>
            <td>
              {{`${item.created_by.first_name} ${item.created_by.last_name}`}}
            </td>
            <td v-if="process.machine.production_unit_m2">
              {{item.mts2}}
            </td>
            <td v-else>
              {{item.qty}}
            </td>
            <td>
              {{item.equipment.name}}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <h3 v-if="process.machine.production_unit_m2" class="my-2">
        Total Mts<sup>2</sup>: <strong>{{totalMts2}}</strong>
      </h3>
      <h3 v-else class="my-2">
        Total Cantidad: <strong>{{totalQty}}</strong>
      </h3>
    </div>
    <h3 v-else class="text text-center pa-4">
      No existen registros
    </h3>
  </v-container>
  </template>
  
  <script>
  export default {
    name:'BitacoraDetails',
    props:['process'],
    computed:{
      totalMts2(){
        var total=0
        this.process.bitacora.map(r => {
          total+=parseFloat(r.mts2)
        })
        return total
      },
      totalQty(){
        var total=0
        this.process.bitacora.map(r => {
          total+=parseFloat(r.qty)
        })
        return total
      }
    }
  }
  </script>
  
  <style>
  
  </style>