<template>
  <v-container>
    <div>
      <v-card-title>
        {{item.id>-1?'Actualizar Industria':'Crear Industria'}}
      </v-card-title>
      <v-card-subtitle>
        Administración de Industria
      </v-card-subtitle>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
          <v-card-text>
            <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
              <v-text-field label='Nombre' v-model='item.name' outlined :error-messages="errors"
                :loading="loading"></v-text-field>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="item.id>-1" color="error" small class="mr-2" @click="()=>{showConfirmDelete=true}" outlined
              :loading="loading"><v-icon small class="mr-2">fas fa-trash</v-icon>Eliminar</v-btn>
            <v-btn type="submit" color="success" small :loading="loading"><v-icon class="mr-2" small>fas
                fa-sd-card</v-icon>Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </div>
    <confirm v-if="item" v-model="showConfirmDelete"
      :message="`¿Confirma eliminar el registro? Esta operación no se puede deshacer.`" @ok="deleteItem(item)"
      @cancel="()=>{showConfirmDelete=false;itemToDelete=''}" />
  </v-container>
</template>
<script>
import {getStore,createStore,updateStore} from '@/api/stocks'
import {getIndustry,createIndustry,updateIndustry,deleteIndustry} from '@/api/customers'
import notifications from '@/utils/notifications'
export default {
  name:'IndustryCreateUpdate',
  props:['id'],
  data() {
    return {
      item:'',
      loading:false,
      showConfirmDelete:false,
    }
  },
  methods:{
    async save(){
      this.loading=true
      if(this.id>-1){
        await updateIndustry(this.id,{
          name:this.item.name,
        })
        .then(async (res)=>{
          notifications.showSuccess('Industria guardada correctamente')
          this.item=""
          this.$emit('close')
          this.$emit('updated')
          await this.$store.dispatch('getIndustries')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Industria '+err)
        })
        this.loading=false
      }
      else{
        await createIndustry({
          name:this.item.name,
        })
        .then(async (res)=>{
          notifications.showSuccess('Industria creada correctamente')
          await this.$store.dispatch('getIndustries')
          this.$emit('close')
          this.$emit('updated')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Industria '+err)
        })
        this.loading=false
      }
      
    },
    async get(){
      if(this.id>-1){
        this.loading=true
        await getIndustry(this.id)
        .then(res=>{
          this.item=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando Industria '+err)
        })
        this.loading=false
      }
      else{
        this.item={
          id:this.id,
          name:'',
        }
      }
    },
    deleteItem(item){
      this.loading=true
      deleteIndustry(item.id)
      .then(async res=>{
        notifications.showSuccess('Industria eliminada correctamente')
        this.showConfirmDelete=false
        await this.$store.dispatch('getIndustries')
        this.$emit('close')
        this.$emit('updated')
      })
      .catch(err=>{
        notifications.showError('Error eliminando Industria '+err)
      })
      this.loading=false
    }
  },
  watch:{
    id:{
      handler:'get'
    }
  },
  created(){
    this.get()
  }

}
</script>

<style>

</style>