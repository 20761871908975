<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo">Procesos</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn @click="$router.push({name:'ImportProcesses',params:{data:{machines:machines,units:units,groups:groups,processes:costs}}})" class="ml-4" text color="primary">
            <v-icon class="mr-2">fas fa-file-import</v-icon>Importar
        </v-btn>
        <v-btn class="ml-4" color="success" @click="create">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4">
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="costs"
            :items-per-page="10"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItems"
            loading-text="Cargando Datos"
            :search="search"
            @update:options="fetch"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right'
            }"

          >
            <template v-slot:top>
              <v-row justify="space-between" align="center">
                <v-col>
                  <v-select
                    v-model="groupFilter"
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    label="Grupo"
                    placeholder="Todos los Grupos"
                    prepend-inner-icon="filter_alt"
                    single-line
                    outlined
                    dense
                    clearable
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="machineFilter"
                    :items="machines"
                    item-text="name"
                    item-value="id"
                    label="Area de Producción"
                    placeholder="Todas las Áreas"
                    prepend-inner-icon="filter_alt"
                    single-line
                    outlined
                    dense
                    clearable
                    class="pa-0"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="search"
                    @keyup="searchTimeOut"
                    label="Buscar"
                    append-icon="fas fa-search"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-dialog
                v-model="showDialog"
                max-width="500px"
              >
                <cost-create-update 
                  :cost=newCost 
                  :groups=groups 
                  @save="save"
                /> 
              </v-dialog>
            </template>
            <template v-slot:item.name="{item}">
            {{ item.name }}
            <v-chip v-if="item.merma>0" class="ml-2" x-small dark color=" text-center orange darken-1">+ {{item.merma }}</v-chip>
            </template>
            <template v-slot:item.price="{ item }">
              {{item.price | moneda}}
            </template>
            <template v-slot:item.unit_name="{ item }">
              <v-chip outlined label class="chip">{{item.unit_name}}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click.stop="edit(item)">fas fa-edit</v-icon>
              <!-- <v-icon class="error--text" @click.stop="deleteItem(item)">fas fa-trash</v-icon> -->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
</v-container>
</template>
<script>
import {fetchCost,createCost, updateCost,fetchGroups,fetchUnits} from '@/api/products'
import ImportProcesses from '@/components/products/costs/ImportProcesses'
import {fetchMachines} from '@/api/production'
import notifications from '@/utils/notifications'

import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'


export default {
  name:'Processes',
  components:{CostCreateUpdate,ImportProcesses},
  data:()=>({
    is_supply:false,
    costs:[],
    search:'',
    showDialog:false,
    importingProcesses:false,
    loading:false,
    groups:[],
    machines:[],
    units:[],
    machineFilter:'',
    groupFilter:'',
    options:{},
    totalItems:0,
    newCost:{
      id:-1,
      group:'',
      unit:'',
      name:'',
      price:0,
      min_price:0,
      is_supply:''
    },
    headers: [
      { text: 'Item',
        value: 'id',
        class: 'table-header2'
      },
      {text: 'Descripción',value:'name',aling:'start',class: 'table-header2'},
      {text: 'Area Prod.',value:'machine_name',align: 'start',class: 'table-header2'},
      {text: 'Valor',value:'price',align: 'end',class: 'table-header2'},
      {text: 'Unidad',value:'unit_name',aling:'start',class: 'table-header2'},
      {text: 'Grupo',value:'group_name',aling:'start',class: 'table-header2'},
      {text: 'Acciones',value:'actions',aling:'start',class: 'table-header2',sortable: false, align:'center'}
    ],
  }),
  methods:{
    edit(item){
      this.newCost=item
      this.showDialog=true
    },
    create(){
      this.newCost={
        id:-1,
        group:'',
        unit:'',
        name:'',
        price:0,
        min_price:0,
        is_supply:this.is_supply,
        machine:''
      }
      this.showDialog=true
    },
    save(cost){
      this.newCost=cost
      this.newCost.is_supply=this.is_supply
      if(this.newCost.id>0) this.updateCost()
      else this.createCost()
    },
    async createCost(){
      await createCost(this.newCost)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        notifications.showSuccess(this.newCost.name + ' Creado')
        })
      .catch((err)=>{
        notifications.showError('Error Creando '+err)
        this.loading=false
        })
      this.fetch()
    },
    async updateCost(){
      if(this.newCost.unit!=2) this.newCost.merma=0
      await updateCost(this.newCost)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        notifications.showSuccess(this.newCost.name + ' Actualizado')
        })
      .catch(async (e)=>{
        this.loading=false
        notifications.showError('Error Actualizando ' + e)
        })
      this.fetch()
    },
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort = ''
      if(sortBy[0]=='group_name'){
        sort = 'group__name'
      }
      else if(sortBy[0]=='unit_name'){
        sort = 'unit__name'
      }
      else if(sortBy[0]=='machine_name'){
        sort = 'machine__name'
      }
      else{
        sort = sortBy[0]
      }
      if(!this.groupFilter) this.groupFilter=''
      if(!this.machineFilter) this.machineFilter=''
      const query=`search=${this.search}&ordering=${sortDesc[0]?`${sort}`:`-${sort}`}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&is_supply=${this.is_supply}&group=${this.groupFilter}&machine=${this.machineFilter}`
      await fetchCost(query)
      .then((data)=>{
        this.totalItems=data.count
        this.costs=data.results
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error en carga de datos '+err)
        this.loading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
  watch:{
    groupFilter(){
      this.options.page=1
      this.fetch()
    },
    machineFilter(){
      this.options.page=1
      this.fetch()
    }
  },
  async mounted(){
    this.loading=true
    this.groups=await fetchGroups(this.is_supply)
    await fetchUnits()
    .then((res)=>{
      this.units=res
    })
    await fetchMachines()
    .then((res)=>{
      this.machines=res
    })
    this.fetch()
    this.loading=false
  }
}
</script>
<style scopped>
.chip{
  width: 4rem !important;
  justify-items: center !important;
}
</style>