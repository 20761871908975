<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col align-items-center>
      <h1 class="text text-h4 titulo"><v-icon class="mr-2 ">fas fa-shopping-basket</v-icon>Ordenes de Compra</h1>
    </v-col>
    <v-col class="text-end d-flex justify-end">
      <export-purchase-orders 
      :disabled="loading" 
      :filter="filter" 
      :search="search"
      :options="options"
      :estatuses="estatuses"
      />
      <Can I="create" a="PurchaseOrder">
        <v-btn class="ml-4" color="success" @click="$router.push({name:'CreatePurchaseOrder',params:{id:-1}})">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nueva
        </v-btn>
      </Can>
    </v-col>
  </v-row>
    <v-card class='pa-4'>
  <v-row>

    <v-col cols="12" md="6" align-self="center">
      <v-text-field hide-details :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada Desde:"></v-text-field>
    </v-col>
    <v-col cols="12" md="6"  align-self="center">
      <v-text-field hide-details :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada Hasta:" ></v-text-field>
    </v-col>
    <v-col>
      <v-select
        v-model="filter.estatus"
        :items="estatuses"
        item-text="text"
        item-value="value"
        label="Estatus"
        placeholder="Todos los Estatus"
        :disabled="loading"
        single-line
        outlined
        dense
        clearable
        prepend-inner-icon="filter_alt"
      />
    </v-col>
    <v-col>
      <v-select
        v-model="filter.tipo"
        :items="tipos"
        item-text="text"
        item-value="value"
        label="Tipo"
        placeholder="Todos los Tipos"
        single-line
        :disabled="loading"
        outlined
        dense
        clearable
        prepend-inner-icon="filter_alt"
      />
    </v-col>
    <v-col>
      <v-text-field
        v-model="search"
        @keyup="searchTimeOut"
        label="Buscar"
        append-icon="fas fa-search"
        :disabled="loading"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="computedHeaders"
          :items="orders"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :server-items-length="parseInt(total)"
          :item-class="()=>{return 'row-style-normal'}"
          :search="search"
          sort-by='created_at'
          :sort-desc="false"
          @click:row='displayDetails'
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }">
          
          <template v-slot:item.id="{ item }">
            {{item.id}} <v-icon small class="ml-2">{{item.stock_service?'fas fa-boxes':'fas fa-tools'}}</v-icon>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{item.created_at | moment('DD/MM/YYYY')}}
          </template>
          <template v-slot:item.net_total="{ item }">
            {{totalOrder(item)| moneda}}
          </template>
          <template v-slot:item.estatus="{ item }">
            <v-chip small label outlined :color="getStatusColor(item)" style="">{{getStatusName(item)}}</v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu
            bottom left offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                v-bind="attrs"
                v-on="on"
                text
                class="ml-2">more_vert</v-icon> 
            </template>
            <v-list dense>
              <Can I="update" a="PurchaseOrder">
                <v-list-item v-if="!Boolean(item.estatus>1)">
                  <v-btn text small :disabled="Boolean(item.estatus>1)" @click.stop="$router.push({name:'CreatePurchaseOrder',params:{id:item.id}})"><v-icon class="mr-2">fas fa-edit</v-icon>Modificar</v-btn>
                </v-list-item>
              </Can>
              
              <Can I="aproove" a="PurchaseOrder">
                <v-list-item v-if="!Boolean(item.estatus>1)">
                  <v-btn text small 
                  @click.stop="()=>{
                    toApprove=item
                    showConfirmApprove=true
                    }">
                  <v-icon class="mr-2">fas fa-clipboard-check</v-icon>Aprobar
                  </v-btn>
                </v-list-item>
              </Can>
              <Can I="revert" a="PurchaseOrder">
                <v-list-item v-if="Boolean(item.estatus>1&&item.estatus!=3)">
                  <v-btn text small 
                  @click.stop="()=>{
                    toRevert=item
                    showConfirmRevert=true
                    }">
                  <v-icon class="mr-2">fas fa-clipboard-check</v-icon>Revertir
                  </v-btn>
                </v-list-item>
              </Can>
              <Can I="print" a="PurchaseOrder">
                <v-list-item v-if="Boolean(item.estatus>1&&item.estatus!=3)">
                  <v-btn text small @click.stop="print(item)"><v-icon class="mr-2">print</v-icon>Imprimir</v-btn>
                </v-list-item>
              </Can>
              <Can I="create" a="PurchaseOrder">
                <v-list-item>
                  <v-btn text small @click.stop="$router.push({name:'CreatePurchaseOrder',params:{id:item.id, duplicate:true}})" ><v-icon class="mr-2">fas fa-clone</v-icon>Duplicar</v-btn>
                </v-list-item>
              </Can>
              <Can I="update" a="PurchaseOrder">
              <v-list-item>
                <v-btn 
                text 
                small 
                @click.stop="()=>{
                  toLinkOt=true
                  purchaseOrderToLink=item
                  }" 
                >
                <v-icon class="mr-2">fas fa-link</v-icon>Vincular OT
                </v-btn>
              </v-list-item>
              </Can>
              
              <Can I="update" a="PurchaseOrder">
              <v-list-item v-if="Boolean(item.estatus>1&&item.estatus<3)">
                <v-btn 
                text 
                small 
                @click.stop="()=>{
                  order=item
                  showConfirmReceived=true
                  }" 
                >
                <v-icon class="mr-2">fas fa-dolly</v-icon>Recepcionar
                </v-btn>
              </v-list-item>
              <v-list-item v-if="Boolean(item.estatus==4)&&!item.stock_service">
                <v-btn 
                text 
                small 
                @click.stop="()=>{
                  order=item
                  showConfirmRevertReceived=true
                  }" 
                  class="error--text"
                >
                <v-icon class="mr-2">fas fa-dolly</v-icon>Anular Recepcion
                </v-btn>
              </v-list-item>  
              </Can>
              <Can I="delete" a="PurchaseOrder">
              <v-list-item v-if="Boolean(item.estatus<3)">
                <v-btn 
                text 
                small 
                @click.stop="()=>{
                  order=item
                  showConfirmCancel=true
                  }" 
                class="error--text">
                <v-icon class="mr-2">fas fa-trash</v-icon>Anular
                </v-btn>
              </v-list-item> 
              </Can>
            </v-list>
          </v-menu>
          </template>
        </v-data-table>
    </v-col>
  </v-row>
    </v-card>
  <confirm
    v-if="toApprove"
    v-model="showConfirmApprove" 
    :message="`Confirma la aprobación de la Orden #${toApprove.id} emitida a ${toApprove.provider_name} por un total neto de $${totalOrder(toApprove)}?`" 
    @ok="approve"
    @cancel="showConfirmApprove=false"/>
    <confirm
    v-if="toRevert"
    v-model="showConfirmRevert" 
    :message="`Confirma la reversion de la Orden #${toRevert.id} emitida a ${toRevert.provider_name} por un total neto de $${totalOrder(toRevert)}?`" 
    @ok="revert"
    @cancel="showConfirmRevert=false"/>
  <confirm
    v-if="order"
    v-model="showConfirmCancel" 
    :message="`Confirma la anulación de la Orden #${order.id} emitida a ${order.provider_name}?`" 
    @ok="cancel"
    @cancel="showConfirmCancel=false"/>
  <confirm
    v-if="order"
    v-model="showConfirmReceived" 
    :message="`Confirma el cambio de Estatus de la Orden #${order.id} emitida a ${order.provider_name} a Recepcionada?`" 
    @ok="receive"
    @cancel="showConfirmReceived=false"/>
    <confirm
    v-if="order"
    v-model="showConfirmRevertReceived" 
    :message="`Confirma el cambio de Estatus de la Orden #${order.id} emitida a ${order.provider_name} a NO Recepcionada?`" 
    @ok="revertReceive"
    @cancel="showConfirmRevertReceived=false"/>
  <v-dialog v-model="showPurchaseOrder" max-width="1200">
    <v-card class="pa-6">  
      <detailed-purchase-order :purchaseOrder="purchaseOrderToDisplay" @close="showPurchaseOrder=false"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="toLinkOt" max-width="500">
    <v-card class="pa-6">
      <v-card-title class="titulo text-h5">Vincular Orden de Trabajo</v-card-title>
      <LinkPOtoOT
      :purchase_order="purchaseOrderToLink"
      @updated="()=>{
        this.toLinkOt=false
        this.fetch()
      }"
      />
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchPurchase,updatePurchase} from '@/api/stocks'
import notifications from '@/utils/notifications'
import ExportPurchaseOrders from '@/components/stocks/ExportPurchaseOrders'
import LinkPOtoOT from '@/components/stocks/LinkPOtoOT'
import DetailedPurchaseOrder from '@/components/stocks/DetailedPurchaseOrder'
import {pdfPurchaseOrder} from '@/doc_generators/purchaseOrder'
import moment from 'moment'
const numeral = require('numeral');

export default {
  name:'PurchaseOrders',
  components:{ExportPurchaseOrders,DetailedPurchaseOrder,LinkPOtoOT},
  data() {
    return {
      loading: false,
      loadingPurchaseOrder:false,
      showPurchaseOrder:false,
      purchaseOrderToDisplay:'',
      purchaseOrderToLink:'',
      orders:[],
      order:'',
      timer:'',
      options:{},
      search:'',
      total:'',
      showDialog:false,
      showConfirmApprove:false,
      showConfirmCancel:false,
      showConfirmReceived:false,
      showConfirmRevert:false,
      showConfirmRevertReceived:false,
      toApprove:'',
      toRevert:'',
      toLinkOt:false,
      work_order_for_link:'',
      estatuses:[
        {
          value:1,
          text:'Ingresada',
          color:'info'
        },
        {
          value:2,
          text:'Aprobada',
          color:'success'
        },
        {
          value:4,
          text:'Recepcionada',
          color:''
        },
        {
          value:3,
          text:'Anulada',
          color:'error'
        },
      ],
      tipos:[
        {
          value:'true',
          text:'Inventario'
        },
        {
          value:'false',
          text:'Servicios'
        },
      ],
      search:'',
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        estatus:'',
        tipo:'',
      },
      
      headers: [
      {
        text: 'Número',
        align: 'start',
        value: 'id',
        class: 'table-header',
        show:true,
        align:'end'
      },
      {
        text: 'Creada',
        align: 'start',
        value: 'created_at',
        class: 'table-header',
        show:true
      },
      {
        text: 'Proveedor',
        align: 'start',
        value: 'provider_name',
        class: 'table-header',
        show:true
      },
      {
        text: 'Referencia',
        align: 'start',
        value: 'title',
        class: 'table-header',
        show:true
      },
      {
        text: 'C. Costo',
        align: 'start',
        value: 'center_name',
        class: 'table-header',
        show:true
      },
      {
        text: 'OT',
        align: 'start',
        value: 'work_order',
        class: 'table-header',
        sortable:false,
        show:true
      },
      {
        text: 'Neto',
        align: 'start',
        value: 'net_total',
        class: 'table-header',
        show:true,
        align:'end'
      },
      {
        text: 'Estatus',
        align: 'start',
        value: 'estatus',
        class: 'table-header',
        align:'center',
        show:true
      },
      {
        text: '',
        align: 'center',
        value: 'actions',
        class: 'table-header',
        show:true
      },
    ],
    }
  },
  methods:{
    totalOrder(item){
      const total = item.details.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
      return total-total*parseFloat(item.discount)/100
    },
    getStatusName(item){
      return this.estatuses.find(i=>i.value==item.estatus).text
    },
    getStatusColor(item){
      return this.estatuses.find(i=>i.value==item.estatus).color
    },
    displayDetails(item){
      this.purchaseOrderToDisplay=item
      this.showPurchaseOrder=true
    },
    print(item){
      pdfPurchaseOrder(item)
    },
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='center_name'){
        sort = 'center__name'
      }
      else if(sortBy[0]=='provider_name'){
        sort = 'provider__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.filter.estatus) this.filter.estatus=''
      if (!this.filter.tipo) this.filter.tipo=''
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&page=${page}&&page_size=${itemsPerPage}&&search=${this.search}&&estatus=${this.filter.estatus}&&stock_service=${this.filter.tipo}&&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}`
      await fetchPurchase(query)
      .then(res=>{
        this.total=res.count
        this.orders=res.results
      })
      .catch((err)=>{
        console.log(err)
        notifications.showError('Error cargando órdenes '+err.error)
      })
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    async approve(){
      await updatePurchase(this.toApprove.id,'','approve=true')
      .then(res=>{
        this.showConfirmApprove=false
        this.toApprove=''
        notifications.showSuccess('Orden de Compra #'+res.id+' aprobada!')
        this.fetch()
      })
      .catch(err=>{
        notifications.showError('Error aprobando orden de compra '+err)
      })
    },
    async revert(){
      await updatePurchase(this.toRevert.id,'','revert=true')
      .then(res=>{
        this.showConfirmRevert=false
        this.toRevert=''
        notifications.showSuccess('Orden de Compra #'+res.id+' revertida!')
        this.fetch()
      })
      .catch(err=>{
        notifications.showError('Error revertiendo orden de compra '+err)
      })
    },
    async cancel(){
      console.log(this.order)
      await updatePurchase(this.order.id,'','cancel=true')
      .then(res=>{
        this.showConfirmCancel=false  
        this.order=''
        notifications.showSuccess('Orden de Compra #'+res.id+' cancelada!')
        this.fetch()
      })
      .catch(err=>{
        notifications.showError('Error cancelando orden de compra '+err)
      })
    },
    async receive(){
      console.log(this.order)
      await updatePurchase(this.order.id,'','receive=true')
      .then(res=>{
        this.showConfirmReceived=false  
        this.order=''
        notifications.showSuccess('Orden de Compra #'+res.id+' Recibida!')
        this.fetch()
      })
      .catch(err=>{
        notifications.showError('Error cambiando estatus a orden de compra '+err)
      })
    },
    async revertReceive(){
      await updatePurchase(this.order.id,'','receive=false')
      .then(res=>{
        this.showConfirmRevertReceived=false  
        this.order=''
        notifications.showSuccess('Orden de Compra #'+res.id+' Revertida!')
        this.fetch()
      })
      .catch(err=>{
        notifications.showError(err)
        this.showConfirmRevertReceived=false
        this.order=''
      })
    },
  },
  watch:{
    options:{
      handler:'fetch',
      deep:true
    },
    filter:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.fetch()
    this.loading=true
    
    this.loading=false
  },
  computed:{
    computedHeaders(){
      return this.headers
    }
  }
}
</script>

<style lang="scss">
.row-style-normal{
    cursor: pointer;
 }
</style>