<template>
  <v-container>
    <div>
      <v-card-title
        class=""
      >
       {{item.id>-1?'Actualizar Rubro':'Crear Rubro'}}
      </v-card-title>
      <v-card-subtitle>Administración de Rubro</v-card-subtitle>
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(save)">
      <v-card-text>
            <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
              <v-text-field
              label='Nombre'
              
              v-model='item.name'
              outlined
              :error-messages="errors"
              :loading="loading"
              ></v-text-field>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
              <v-btn v-if="item.id>-1" small color="error" class="mr-2" @click="()=>{showConfirmDelete=true}" outlined :loading="loading"><v-icon class="mr-2" small>fas fa-trash</v-icon>Eliminar</v-btn>
              <v-btn type="submit" small color="success" :loading="loading"><v-icon class="mr-2" small>fas fa-sd-card</v-icon>Guardar</v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
    </div>
    <confirm
      v-if="item"
      v-model="showConfirmDelete" 
      :message="`¿Confirma eliminar el registro? Esta operación no se puede deshacer.`"
      @ok="deleteItem(item)"
      @cancel="()=>{showConfirmDelete=false;itemToDelete=''}"/>
  </v-container>
</template>
<script>

import {getRubro,createRubro, updateRubro, deleteRubro} from '@/api/customers'
import notifications from '@/utils/notifications'
export default {
  name:'IndustryCreateUpdate',
  props:['id'],
  data() {
    return {
      item:'',
      loading:false,
      showConfirmDelete:false,
    }
  },
  methods:{
    async save(){
      this.loading=true
      if(this.id>-1){
        await updateRubro(this.id,{
          name:this.item.name,
        })
        .then(async (res)=>{
          notifications.showSuccess('Rubro guardado correctamente')
          this.item=""
          this.$emit('close')
          this.$emit('updated')
          await this.$store.dispatch('getRubros')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Rubro '+err)
        })
        this.loading=false
      }
      else{
        await createRubro({
          name:this.item.name,
        })
        .then(async (res)=>{
          notifications.showSuccess('Industria creada correctamente')
          this.$store.dispatch('getRubros')
          this.$emit('close')
          this.$emit('updated')
          this.item.name=""
          await this.$store.dispatch('getRubros')
        })
        .catch(err=>{
          notifications.showError('Error actualizando Rubro '+err)
        })
        this.loading=false
      }
      
    },
    async get(){
      if(this.id>-1){
        this.loading=true
        await getRubro(this.id)
        .then(res=>{
          this.item=res
        })
        .catch((err)=>{
          notifications.showError('Error cargando Rubro '+err)
        })
        this.loading=false
      }
      else{
        this.item={
          id:this.id,
          name:'',
        }
      }
    },
    deleteItem(item){
      this.loading=true
      deleteRubro(item.id)
      .then( async res=>{
        notifications.showSuccess('Rubro eliminada correctamente')
        this.showConfirmDelete=false
        this.$emit('close')
        this.$emit('updated')
        await this.$store.dispatch('getRubros')
      })
      .catch(err=>{
        notifications.showError('Error eliminando Rubro '+err)
      })
      this.loading=false
    }
  },
  watch:{
    id:{
      handler:'get'
    }
  },
  created(){
    this.get()
  }

}
</script>

<style>

</style>