var numeral = require('numeral');
import getInternacional,{internacional} from '../utils/internacional';


internacional.forEach((element) => {
    numeral.register('locale', element.code, {
    delimiters: {
        thousands:element.currency_thousands_separator,
        decimal:element.currency_decimals_separator
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol:element.currency_symbol,
    }
    });
});

const decimals=["",".0",".00",".000"]

export const formats = internacional.map((element) => {
    return {
        code: element.code,
        name: element.country,
        currency:"$0,0"+decimals[element.currency_decimals],
        iva_title:element.purchase_tax_label,
    }
})


export const findFormat = (code) => {
    return formats.find(format => format.code === code) || formats[0];
}



export default numeral;