<template>
  <v-container>
    <v-skeleton-loader
          v-if="loading"
          type="actions,text,text,text,text,card,card"
    />
    <div v-if="!loading">
      <v-row class="mb-2 justify-space-between">
        <v-col cols="10">
          <h1 class="text text-h4 titulo"><v-icon class="mr-2">fas fa-box-open</v-icon>Artículo <strong>[{{article.sku}}] {{article.name}}</strong></h1>
        </v-col>
        <v-col class="text-end">
          <div class="flex justify-space-around">
            <v-btn @click="$router.go(-1)" class="ml-2" text ><v-icon class="mr-2">fas fa-chevron-left</v-icon></v-btn>
          </div>
        </v-col>  
      </v-row>
      <v-row justify="center">
        <v-col>
            <table>
              <tbody>
                <tr>
                  <td class="">
                    Familia:
                  </td>
                  <td class="text-left">
                    <div class="text font-weight-black ml-4">{{article.family_name}}</div>
                  </td>
                </tr>
                <tr>
                  <td class="">
                    Unidad de Compra:
                  </td>
                  <td class="text-left">
                    <div class="text font-weight-black ml-4">{{article.unit_name}}</div>
                  </td>
                </tr>
                <tr>
                  <td class="">
                    Inventario Crítico:
                  </td>
                  <td class="text-left">
                    <div class="text font-weight-black ml-4">{{article.critic |number('0,0[.]0')}}</div>
                  </td>
                </tr>
                <tr>
                  <td class="">
                    Última Compra:
                  </td>
                  <td class="text-left">
                    <div class="text font-weight-black ml-4">{{article.last_buy_date | moment('DD/MM/YYYY')}}</div>
                  </td>
                </tr>
                <tr>
                  <td class="">
                    Observaciones:
                  </td>
                  <td class="text-left">
                    <div class="text font-weight-black ml-4">{{article.notes}}</div>
                  </td>
                </tr>
              </tbody>
            </table>
        </v-col>
        <v-col>
            <table>
              <tbody>
                <tr>
                  <td class="">
                    Existencias:
                  </td>
                  <td class="text-right">
                    <div class="text font-weight-black ml-6">{{totalStock(article) | number('0,0[.]0')}}
                    <v-menu bottom left offset-y> 
                      <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs"
                          v-on="on"
                          text
                        small class="ml-2">fas fa-chevron-down</v-icon>
                      </template>
                      <v-card class="pa-2">
                        <div v-if="article.stocks.length>0">
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th>
                                  Bodega
                                </th>
                                <th>
                                  Existencia
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for='register in article.stocks' :key='register.id'>
                                <td>
                                  {{register.store_name}}
                                </td>
                                <td class="text-end">
                                  {{register.qty | number('0,0[.]0')}}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                        <h3 v-else class="text text-center pa-4">
                          No hay existencias
                        </h3>
                      </v-card>
                    </v-menu>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="">
                    Valor Promedio:
                  </td>
                  <td class="text-right">
                    <div class="text font-weight-black ml-4"> {{article.avg| moneda}}</div>
                  </td>
                </tr>
                <tr>
                  <td class="">
                    Valor Total:
                  </td>
                  <td class="text-right">
                    <div class="text font-weight-black ml-4">{{article.avg*totalStock(article) | moneda}}</div>
                  </td>
                </tr>
                <tr>
                  <td class="">
                    Precio Última Compra:
                  </td>
                  <td class="text-right">
                    <div class="text font-weight-black ml-4">{{article.last_buy_price | moneda}}</div>
                  </td>
                </tr>
              </tbody>
            </table>
        </v-col>
    </v-row>
    <v-row class="justify-space-between">
      <v-col cols="8" class="py-0">
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-history</v-icon>Movimientos</h1>
      </v-col>  
      <v-col class="py-0">
        <v-radio-group 
          v-model="optionSelected" 
          row
          dense
          hide-details
          @change="chartDataFetch"
        >
          <div v-for="data in dataRange" :key="data.text">
            <v-radio
              :label="data.text"
              :value="data"
              :disabled="chartDataLoading"
            ></v-radio>
          </div>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <!-- <v-col cols="6" class="py-0">
        <article-price-graph :article="article.id"/>
      </v-col> -->
      <v-col>
        <article-price-graph :datacollection="priceDataCollection"/>
      </v-col>
      <v-col>
        <article-out-graph :datacollection="outDataCollection"/>
      </v-col>

    </v-row>
    <v-row class="" >
      <v-col align-self="center">
        <v-text-field hide-details :disabled="transactionLoading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada Desde:"></v-text-field>
      </v-col>
      <v-col align-self="center">
        <v-text-field hide-details :disabled="transactionLoading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada Hasta:" ></v-text-field>
      </v-col>
      <!-- <v-col>
        <v-select
          v-model="filter.store"
          :items="stores"
          item-text="name"
          item-value="id"
          label="Bodega"
          placeholder="Todas las Bodegas"
          
          :disabled="loading"
          outlined
          hide-details
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col> -->
      <!-- <v-col align-self="center">
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="fas fa-search"
          @keyup="searchTimeOut"
          :disabled="loading"
          hide-details
        ></v-text-field>
      </v-col> -->
    </v-row>
    <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="headers"
          :items="transactions"
          :items-per-page="-1"
          :loading="transactionLoading"
          :search="search"
          sort-by='created_at'
          dense
          :item-class="itemRowStyle"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right'
          }">
          <template v-slot:body.prepend>
            <tr>
              <td colspan="10" class="text-end">
                ---------- Saldo Inicial ----------
              </td>
              <td class="text-end font-weight-black">
                {{initial_balance|number('0,0[.]0')}}
              </td>
            </tr>
          </template>
          <template v-slot:body.append>
            <tr>
              <td colspan="10" class="text-end">
                ---------- Saldo Final ----------
              </td>
              <td class="text-end font-weight-black">
                {{final_balance|number('0,0[.]0')}}
              </td>
            </tr>
          </template>
          <template v-slot:item.name="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{item.created_at | moment('DD/MM/YYYY')}}
          </template>
          <template v-slot:item.price="{ item }">
            <div v-if="item.tipo_in_out">{{item.price | moneda}}</div>
            <div v-else class="error--text">({{item.price | moneda}})</div>
          </template>
          <template v-slot:item.total="{ item }">
            <div v-if="item.tipo_in_out">{{item.price*item.qty | moneda}}</div>
            <div v-else class="error--text">({{Math.round(item.price)*Math.round(item.qty)  | moneda}})</div>
          </template>
          <template v-slot:item.created_by="{ item }">
            {{`${item.created_by.first_name} ${item.created_by.last_name}`}}
          </template>
          <template v-slot:item.tipo="{ item }">
             {{item.tipo_name}}<v-icon class="ml-2 align-items-center">{{item.tipo_in_out?'login':'logout'}}</v-icon>
          </template>
          <template v-slot:item.qty_in="{ item }">
             <div v-if="item.tipo_in_out">{{item.qty|number('0,0[.]0')}}</div>
          </template>
          <template v-slot:item.qty_out="{ item }">
             <div v-if="!item.tipo_in_out">{{item.qty|number('0,0[.]0')}}</div>
          </template>
          
          <template v-slot:item.balance="{ item }">
             {{item.balance|number('0,0[.]0')}}
          </template>
        </v-data-table>
    </v-col>
  </v-row>
  </div>  
  </v-container>
</template>
<script>
import {getArticle,fetchTransactions,fetchStores} from '@/api/stocks'
import ArticlePriceGraph from '@/components/stocks/ArticlePriceGraph'
import ArticleOutGraph from '@/components/stocks/ArticleOutGraph'
import NumberLabel from '@/components/utils/NumberLabel'
import moment from 'moment'

export default {
  name:'DetailedArticle',
  components:{NumberLabel,ArticlePriceGraph,ArticleOutGraph},
  async mounted(){  
    if(this.$route.params.id){
      await getArticle(this.$route.params.id)
      .then((data)=>{
        this.article=data
        this.loading=false
      })
      .catch((err)=>notifications.showError('Error Cargando Artículo' + err))
      this.fetch()
      
      //this.stores=await fetchStores()

      //Retreive chart Data
      this.optionSelected = this.dataRange[0]
      await this.chartDataFetch()
    }
  },
  data() {
    return {
      loading:false,
      transactionLoading:false,
      article:'',
      transactions:[],
      stores:[],
      store:'',
      initial_balance:0,
      final_balance:0,
      headers:[
      { text: 'Creada', value: 'created_at' ,class:'table-header',sortable:false,},
      { text: 'Usuario', value: 'created_by_full_name' ,class:'table-header',sortable:false,},
      { text: 'Ref.', value: 'notes',class:'table-header',sortable:false},
      { text: 'Centro', value: 'center_name',class:'table-header',sortable:false},
      { text: 'Bodega', value: 'store_name',class:'table-header',sortable:false},
      { text: 'Movimiento', value: 'tipo',class:'table-header' ,sortable:false,},
      { text: 'Entrada', value: 'qty_in',class:'table-header',sortable:false,align:'end' },
      { text: 'Salida', value: 'qty_out',class:'table-header' ,sortable:false,align:'end'},
      { text: 'Valor', value: 'price',class:'table-header',sortable:false,align:'end'},
      { text: 'Total', value: 'total',class:'table-header',sortable:false,align:'end'},
      {text:'Balance',value:'balance',class:'table-header',sortable:false,align:'end'}
    ],
    filter:{
      created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
      created_before:'',
      store:''
    },
    search:'',
    //Chart Feed Data 
    dataRange:[
        {
        text:  moment(new Date().toString()).format('MMMM-YYYY'),
        after: moment(new Date().toString()).format('YYYY')+'-'+moment(new Date().toString()).format('MM')+'-01',
        before:moment(new Date().toString()).format('YYYY-MM-DD'),
        format:'DD-MM-YYYY',
        },
        {
          text:'30 días',
          after: moment(new Date().toString()).add(-1,'month').format('YYYY-MM-DD'),
          before:moment(new Date().toString()).format('YYYY-MM-DD'),
          format:'DD-MM-YYYY',
        },
        {
          text:'12 Meses',
          after:moment(new Date().toString()).add(-1,'year').format('YYYY-MM-DD'),
          before:moment(new Date().toString()).format('YYYY-MM-DD'),
          format:'MMM',
        }
      ],
      optionSelected:{},
      priceDataCollection:[],
      outDataCollection:[],
      chartDataLoading:false,
    }

  },
  methods:{
    totalStock(item){
      if(item){
        if(this.store){
          const filteredStocks = item.stocks.filter(i=>i.store==this.store)
          return filteredStocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
        }
        else return item.stocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
      }
      else return 0
    },
    async fetch(){
      this.transactionLoading=true
      this.initial_balance=0
      this.final_balance=0
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if (!this.filter.store) this.filter.store=''
      const query=`tarticle=${this.$route.params.id}&store=${this.filter.store}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}`
      await fetchTransactions(query)
      .then(res=>{
        const data=res.results
        this.transactions=data
        if(data[0]) {
          this.initial_balance=data[0].initial_qty
          this.final_balance=data[data.length-1].balance
        }
        this.transactionLoading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch(this.$route.params.id)
      }, 800);
    },
    itemRowStyle(item){
      return item.tipo_in_out&&item.tipo_price_effect ? 'font-weight-black':''
    },
    async chartDataFetch(){
      this.chachartDataLoading=true
      //Arreglando variables de fecha en caso de ser null
      const query=`tarticle=${this.$route.params.id}&created_after=${this.optionSelected.after}&created_before=${this.optionSelected.before}`
      await fetchTransactions(query)
      .then(res=>{
        console.log(res)
        // Data for Price Chart
        this.priceDataCollection={
          labels: res.results.map(d=>moment(d.created_at).format('DD/MM/YY')),
          datasets: [
            {
              label: 'Precio Promedio',
              borderColor: '#144d3f',
              data: res.results.map(d=>d.calculated_avg)
            }
          ]
        }
        // Data for Out Chart
        const format = this.optionSelected.format
        const onlyOut=res.results.filter(t=>!t.tipo_in_out)
        const results_by_Date = onlyOut.reduce(function(r, a) {
          r[moment(a.created_at).format(format)] = r[moment(a.created_at).format(format)] || [];
          r[moment(a.created_at).format(format)].push(a);
          return r;
        }, Object.create(null));

        
        var outDataResults=[]
        Object.keys(results_by_Date).forEach(date => {
          var totals = {
              date: date,
              qty: results_by_Date[date].reduce((acc,val)=>{return acc + parseFloat(val.qty);},0)
          }
          outDataResults.push(totals)
        })
        //productionProducts.reduce((acc, val)=>{ return acc + parseFloat(val.total_sales_price); }, 0),
        this.outDataCollection={
          labels: outDataResults.map(d=>d.date),
          datasets:[
            {
              label:'Salidas',
              data: outDataResults.map(d=>d.qty),
              backgroundColor: '#144d3f',
              borderColor:'#144d3f',
            }
          ]
        }
        this.chachartDataLoading=false
      })
    }
  },
  watch:{
    filter:{
      deep: true,
      handler:'fetch'
    }
  }

}
</script>
