<template>
<div>
  <v-btn :disabled="buttonDisabled" text icon color="white"  title="Exportar" :loading="loading" @click="fetch"><v-icon>fas fa-file-export</v-icon></v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="secuenceData"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
export default {
  name:"ExportMachinePlanner",
  props:['secuence','options','disabled','machine'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      dataReady:false,
      labels:{ 
        secuence:{title:'Sec.'},
        orden:{title:'OT'},
        customer_name:{title:'Cliente'},
        //seller_name:{title:'Vendedor'},
        product:{title:'Producto'},
        name: { title: 'Proceso' },
        total:{title:'Total'},
        completed:{title:'Completado'},
        pending:{title:'Pendiente'},
        units:{title:'UN'},
        machine:{title:'Area'},
        equipmentAsigned_name:{title:'Equipo Asignado'},
        delivery_date:{title:'Fecha Entrega'},
        //install_date:{title:'Fecha Instalación'},
        //uninstall_date:{title:'Fecha Desinstalación'},
       },
       csvTitle:`export_planificación_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  computed:{
    secuenceData(){
      return this.secuence.map(i=>{
        return {
          secuence:this.secuence.indexOf(i)+1,
          orden:i.work_order,
          customer_name:i.customer_name,
          product:i.product_name,
          name:i.name,
          total:i.unit_m2?numeral(i.product_mts2).format('0[.]00'):numeral(i.product_qty).format('0[.]00'),
          completed:i.unit_m2?numeral(i.total_mts2).format('0[.]00'):numeral(i.total_qty).format('0[.]00'),
          pending:i.unit_m2?numeral(i.product_mts2-i.total_mts2).format('0[.]00'):numeral(i.product_qty-i.total_qty).format('0[.]00'),
          units:i.unit_m2?'MTS2':'UNID',
          machine:this.machine?.name,
          delivery_date:moment(i.delivery_date).format('DD-MM-YYYY'),
          install_date:"",//i.install_date?moment(i.install_date).format('DD-MM-YYYY'):'',
          uninstall_date:"",//i.uninstall_date?moment(i.uninstall_date).format('DD-MM-YYYY'):'',
          seller_name:"",//i.seller_name,
          equipmentAsigned_name:i.equipment_assigned//i.equipmentAsigned_name?i.equipmentAsigned_name:''
        }
      })
    },
    buttonDisabled(){
      return this.disabled
    },
  },
  methods:{
    async fetch(){
      this.dataReady=true
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }

}
</script>