<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo">Materiales</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn text @click="$router.push({name:'ImportSupplies',params:{data:{units:units,groups:groups,supplies:costs}}})" class="ml-4" color="success">
            <v-icon class="mr-2">fas fa-file-import</v-icon>Importar
        </v-btn>
        <v-btn class="ml-4" color="success" @click="create">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4">
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="costs"
            :items-per-page="10"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItems"
            loading-text="Cargando Datos"
            :search="search"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right'
            }"
            @update:options="fetch"
          >
            <template v-slot:top>
              <v-row justify="space-between">
                <v-col cols="4">
                  <v-select
                    v-model="groupFilter"
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    label="Grupo"
                    placeholder="Todos los Grupos"
                    prepend-inner-icon="filter_alt"
                    single-line
                    outlined
                    dense
                    clearable
                    :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'fas fa-angle-double-left',
                    lastIcon: 'fas fa-angle-double-right',
                    prevIcon: 'fas fa-angle-left',
                    nextIcon: 'fas fa-angle-right'
                    }"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    @keyup="searchTimeOut"
                    :disabled="loading"
                    label="Buscar"
                    append-icon="fas fa-search"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.name="{ item }">
              {{item.name}} 
           
              <v-tooltip top v-if="item.has_dimension">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 info--text" small v-bind="attrs" v-on="on">fas fa-ruler-combined</v-icon>
                </template>
                <span>{{item.width}}x{{item.height}}</span>
              </v-tooltip>
            
              <v-tooltip top v-if="item.has_article" >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 info--text" small v-bind="attrs" v-on="on">fas fa-link</v-icon>
                </template>
                <span>{{item.article.sku}}</span>
              </v-tooltip>
              
            </template>
            <template v-slot:item.price="{ item }">
              {{item.price | moneda}}
            </template>
            <template v-slot:item.is_active="{ item }">
              <v-switch v-model="item.is_active" @change="updateStatus(item)" :disabled="!$can('update','Cost')"></v-switch>
            </template>
            <template v-slot:item.unit_name="{ item }">
              <v-chip outlined label class="chip">{{item.unit_name}}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn  @click.stop="edit(item)" icon>
                <v-icon>fas fa-edit</v-icon>
              </v-btn>
              <v-btn  @click.stop="showPriceLog(item)" icon>
                <v-icon class="">fas fa-clipboard-list</v-icon>
              </v-btn>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="showDialog"
      max-width="500px"
    >
      <cost-create-update 
        :cost=newCost 
        :groups=groups 
        @save="save"
      /> 
    </v-dialog>
    <v-dialog
      v-model="showPriceLogDialog"
      max-width="800px"
    >
      <v-card class="pa-4">
      <price-log-table 
        :cost=selectedCost 
        @close="showPriceLogDialog = false"
        /> 
      </v-card>
    </v-dialog>
  
</v-container>
</template>
<script>
import {fetchCost,createCost, updateCost,fetchGroups,fetchUnits} from '@/api/products'
import notifications from '@/utils/notifications'

import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'
import PriceLogTable from '@/components/products/costs/PriceLogTable'
export default {
  name:'Supplies',
  components:{CostCreateUpdate,PriceLogTable},
  data:()=>({
    is_supply:true,
    costs:[],
    search:'',
    timer:'',
    showDialog:false,
    loading:false,
    groups:[],
    groupFilter:'',
    options:{},
    totalItems:0,
    units:[],
    showPriceLogDialog:false,
    selectedCost:'',
    newCost:{
      id:-1,
      group:'',
      unit:'',
      name:'',
      price:0,
      min_price:0,
      is_supply:'',
      has_dimension:false,
      width:0,
      height:0,
      has_article:false,
      price_update_option:0,
    },
    headers: [
      { text: 'Item',
        value: 'id',
        class: 'table-header2'
      },
      {text: 'Descripción',value:'name',aling:'start',class: 'table-header2'},
      {text: 'Valor',value:'price',align: 'end',class: 'table-header2'},
      {text: 'Unidades',value:'unit_name',aling:'start',class: 'table-header2'},
      {text: 'Grupo',value:'group_name',aling:'start',class: 'table-header2'},
      {text: 'Estatus',value:'is_active',aling:'center',class: 'table-header2'},
      {text: '',value:'actions',aling:'start',class: 'table-header2',sortable: false, align:'center'}
    ],
  }),
  watch:{
    async groupFilter(){
      this.options.page=1
      this.fetch()
    }
  },
  methods:{
    edit(item){
      this.newCost=item
      this.showDialog=true
    },
    showPriceLog(item){
      this.showPriceLogDialog=true
      this.selectedCost=item
    },
    create(){
      this.newCost={
        id:-1,
        group:'',
        unit:'',
        name:'',
        price:0,
        is_supply:this.is_supply,
        machine:'',
        has_dimension:false,
        width:0,
        height:0,
        has_article:false,
        price_update_option:0,
        article_id:'',
      }
      this.showDialog=true
    },
    save(cost){
      this.newCost=cost
      this.newCost.is_supply=this.is_supply
      if(this.newCost.id>0) this.updateCost()
      else this.createCost()
    },
    async createCost(){
      const cost={
        id:this.newCost.id,
        group:this.newCost.group,
        unit:this.newCost.unit,
        name:this.newCost.name,
        price:this.newCost.price,
        min_price:this.newCost.min_price,
        is_supply:this.is_supply,
        machine:this.newCost.machine,
        has_dimension:this.newCost.has_dimension,
        width:this.newCost.width,
        height:this.newCost.height,
        has_article:this.newCost.has_article,
        price_update_option:this.newCost.price_update_option,
        article_id:this.newCost.article_id,
      }
      await createCost(cost)
      .then(async (res)=>{
        console.log(res)
        this.showDialog=false
        this.loading=false
        notifications.showSuccess(this.newCost.name + ' Creado')
        })
      .catch((err)=>{
        notifications.showError('Error Creando '+err)
        this.loading=false
        })
      this.fetch()
    },
    async updateCost(){
      const cost={
        id:this.newCost.id,
        group:this.newCost.group,
        unit:this.newCost.unit,
        name:this.newCost.name,
        price:this.newCost.price,
        min_price:this.newCost.min_price,
        is_supply:this.is_supply,
        machine:this.newCost.machine,
        has_dimension:this.newCost.has_dimension,
        width:this.newCost.width,
        height:this.newCost.height,
        has_article:this.newCost.has_article,
        price_update_option:this.newCost.price_update_option,
        article_id:this.newCost.article_id,
      }
      await updateCost(cost)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        notifications.showSuccess(this.newCost.name + ' Actualizado')
        })
      .catch(async (e)=>{
        this.loading=false
        notifications.showError('Error Actualizando ' + e)
        })
      this.fetch()
    },
    async updateStatus(item){
       const cost={
        id:item.id,
        group:item.group,
        unit:item.unit,
        name:item.name,
        price:item.price,
        min_price:item.min_price,
        is_supply:true,
        has_dimension:item.has_dimension,
        width:item.width,
        height:item.height,
        is_active:item.is_active
      }
      if(item.has_article){
        cost.has_article=true
        cost.article_id=item.article.id
      }
      await updateCost(cost)
      .then(()=>{
        notifications.showSuccess('Estatus '+ item.name + ' Actualizado')
      })
      .catch((e)=>{
        notifications.showError('Error Actualizando Estatus '+e)
      })
    },
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort = ''
      if(sortBy[0]=='group_name'){
        sort = 'group__name'
      }
      else if(sortBy[0]=='unit_name'){
        sort = 'unit__name'
      }
      else{
        sort = sortBy[0]
      }
      if(!this.groupFilter) this.groupFilter=''
      const query=`search=${this.search}&ordering=${sortDesc[0]?`${sort}`:`-${sort}`}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&is_supply=${this.is_supply}&group=${this.groupFilter}`
      await fetchCost(query)
      .then((data)=>{
        this.totalItems=data.count
        this.costs=data.results
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error en carga de datos '+err)
        this.loading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
  watch:{
    options:{
      handler:'fetch',
      deep:true
    },
    groupFilter:{
      handler:'fetch',
    }
  },
  async mounted(){
    this.loading=true
    this.fetch()
    this.groups=await fetchGroups(this.is_supply)
    await fetchUnits()
    .then((res)=>{
      this.units=res
    })
    this.loading=false
  }
}
</script>
<style scoped>
.chip{
  width: 4rem !important;
  justify-items: center !important;
}
</style>