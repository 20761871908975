<template>
    <div>
      <v-btn :disabled="disabled" color="secondary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
      <v-dialog v-model="dataReady" max-width="500">
        <v-card>
          <v-card-title>
            <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
          </v-card-title>
          <v-card-text class="text-center">
            <vue-json-to-csv
              :json-data="workOrders"
              :labels="labels"
              :csv-title="csvTitle"
              :separator="';'"
              @success="val => handleSuccess(val)"
              @error="val => handleError(val)">
              <v-btn color="success">
                <v-icon class="mr-2">fas fa-download</v-icon> <b>Lista</b>
              </v-btn>
            </vue-json-to-csv>
            <vue-json-to-csv
              class="ml-2"
              :json-data="workOrdersDetail"
              :labels="labelsDetails"
              :csv-title="csvTitle"
              :separator="';'"
              @success="val => handleSuccess(val)"
              @error="val => handleError(val)">
              <v-btn color="success">
                <v-icon class="mr-2">fas fa-download</v-icon> <b>Detalle</b>
              </v-btn>
            </vue-json-to-csv>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    </template>
    
    <script>
    import notifications from '@/utils/notifications'
    import {fetchWorkOrders} from '@/api/production'
    import {fetchWorkOrdersMoney} from '@/api/reports'
    import VueJsonToCsv from 'vue-json-to-csv'
    import moment from 'moment'
    import numeral from 'numeral'
    export default {
      name:"ExportWorkOrdersMoney",
      props:['filter','options','disabled','search'],
      components: {VueJsonToCsv,},
      data() {
        return {
          loading: false,
          workOrders:[],
          workOrdersDetail:[],
          dataReady:false,
          labels:{ 
            id:{title:'OT'},
            created_at:{title:'Creada'},
            completed_at:{title:'Completada'},
            sales_note__id:{title:'Cotización'},
            sales_note__name: { title: 'Proyecto' },
            sales_note__customer__name:{title:'Cliente'},
            sales_note__customer__seller__first_name:{title:'Vendedor'},
            delivery_date:{title:'Fecha de entrega'},
            total:{title:'Total'},
           },
           labelsDetails:{
            work_order:{title:'OT'},
            created_at:{title:'Creada'},
            completed_at:{title:'Completada'},
            sales_note_name: { title: 'Proyecto' },
            customer_name:{title:'Cliente'},
            seller:{title:'Vendedor'},
            delivery_date:{title:'Fecha de entrega'},
            sku:{title:'SKU'},
            name:{title:'Producto'},
            qty:{title:'Cantidad'},
            width:{title:'Ancho'},
            height:{title:'Alto'},
            mts2:{title:'Mts2'},
            unit_price:{title:'Precio Unitario'},
            total:{title:'Total'},
           },
           csvTitle:`export_orden_trabajo_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
        }
      },
      methods:{
        async fetch(){
            this.loading=true
            var created_before = ''
            var created_after = ''
            if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
            if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')

            if(!this.filter.delivery_after) this.filter.delivery_after=''
            if(!this.filter.delivery_before) this.filter.delivery_before=''
            if(!this.filter.seller) this.filter.seller=''


            const {sortBy, sortDesc,page, itemsPerPage }=this.options
            const query = `page=1&ordering=${sortDesc[0]?`${sortBy[0]}`:`-${sortBy[0]}`}&search=${this.search}&created_after=${created_after}&created_before=${created_before}&delivery_after=${this.filter.delivery_after}&delivery_before=${this.filter.delivery_before}&completed=${this.filter.status}&sales_note__customer__seller=${this.filter.seller}&search=${this.search}`
            this.workOrdersDetail=[]
            fetchWorkOrdersMoney(query)
            .then(data=>{
                console.log(data)
                this.workOrders=data.results.map(item=>{
                item.created_at=moment(item.created_at).format('DD-MM-YYYY')
                item.delivery_date=moment(item.delivery_date).format('DD-MM-YYYY')
                item.completed_at=item.completed_at?moment(item.completed_at).format('DD-MM-YYYY'):''
                item.total=Math.round(item.totals.total)
                
                item.products.forEach(p=>(
                  this.workOrdersDetail.push({
                  work_order:item.id,
                  customer_name:item.sales_note__customer__name,
                  sales_note_name:item.sales_note__name,
                  seller:item.sales_note__customer__seller__first_name,
                  created_at:item.created_at,
                  delivery_date:item.delivery_date,
                  completed_at:item.completed_at,
                  sku:p.sku,
                  name:p.name,
                  qty:p.qty,
                  width:p.width,
                  height:p.height,
                  mts2:p.mts2, //numeral(p.mts2).format('0[.]00') ,
                  unit_price:Math.round(p.qty!=0?((p.discountedTotalPrice/p.qty)):0),
                  total:Math.round(p.discountedTotalPrice),
                  })
                  ))
                return item
            })

            this.dataReady=true
            this.loading=false
            })
            .catch(err=>{
            console.log(err)
            })
            .finally(()=>{
            this.loading=false
            })
        },
        handleSuccess(val){
          console.log(val)
          notifications.showSuccess('Ordenes de Trabajo Exportadas')
          this.dataReady=false
        },
        handleError(val){
          console.log(val)
          notifications.showError('Error Exportando Ordenes de Trabajo')
          this.dataReady=false
        }
      }
    
    }
    </script>