import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es',
    },
    theme: {
        dark: false,
        themes: {
            light: {
                //primary: '#33425B',
                //secondary: '#484CB0',
                //success: '#5BAAEC',
                main: '#5b5a59',
                primary: '#0ebf01',
                secondary: '#70a861',
                info: '#4601bc',
                terciary:'ffbb24',
                success: '#0ebf01',
                dark_blue:'#000',
                medium_blue:'#0480d3',
                light_blue:'#0480d3',
                custom_yellow:'ffb224',
                custom_orange:'fe7d1e'
                //accent: '#8c9eff',
                //error: '#b71c1c',
            },
            dark: {
                //primary: '#DDDC00',
            }
        },
    }
});