<template>
<div>
  <v-btn :disabled="disabled" color="secondary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchInvoicedProducts} from '@/api/reports'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportProductInvoice",
  props:['filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      dataReady:false,
      labels:{ 
        product__sku:{title:'Código',},
        product__name:{title:'Nombre'},
        product__family__name:{title:'Familia'},
        product__line__name: { title: 'Linea' },
        total: { title: 'Neto' },
       },
       csvTitle:`export_invoices-by-product_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.seller) this.filter.seller=''
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      let  sort = (sortDesc[0] ? '-'+sortBy[0] : sortBy[0])
      if (!sort||!sortBy[0]) sort = 'product__sku'
      const query=`page=1&ordering=${sort}&emited_after=${this.filter.created_after}&emited_before=${this.filter.created_before}&dte__customer__seller__id=${this.filter.seller}&dte__tipo=30`
      await fetchInvoicedProducts(query)
      .then((res)=>{
        this.items=res.results.map(i=>({
          ...i,
          product__name:i.product__name.replaceAll("\"", ""),
          qty:numeral(i.qty).format('0'),
          total:numeral(i.total).format('0')
        }))
        console.log(this.items)
        this.dataReady=true
      })
      .catch((err)=>{
        notifications.showError('Error cargando documentos '+err)
      })
      this.loading=false
    },
    handleSuccess(val){
      notifications.showSuccess('Documentos Exportados')
      this.dataReady=false
    },
    handleError(val){
      notifications.showError('Error Exportando Documentos')
      this.dataReady=false
    }
  }

}
</script>