<template>
  <v-container>
    <v-row class="mb-4" justify="space-between" align-content="center">
      <h1 class="text text-h4 titulo"><v-icon class="mr-2" x-large>leaderboard</v-icon>Ventas por Familia</h1>
      <export-sales-by-family :data="family"/>
    </v-row>
    <v-row>
      <v-col cols="4">
        <sales-product-graph 
        :data="sortedData.bySales" 
        :icon="'fas fa-money-bill-wave'" 
        :values="sortedData.bySales.map(d=>d.total_sales)"
        :height="150"
        title="Ranking Ventas"
        />
      </v-col>
      <v-col cols="4">
        <sales-product-graph 
        :data="sortedData.byMts2" 
        :icon="'square_foot'" 
        :values="sortedData.byMts2.map(d=>d.total_mts2)"
        :height="150"
        title="Ranking Mts2"
        />
      </v-col>
      <v-col cols="4">
        <sales-product-graph 
        :data="sortedData.byQty" 
        :icon="'square_foot'" 
        :values="sortedData.byQty.map(d=>d.total_qty)"
        :height="150"
        title="Ranking Cantidad"
        />
      </v-col>
    </v-row>
    <v-card class="pa-4">
      <v-row class="" >
        <v-col align-self="center">
          <v-text-field hide-details :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada Desde:"></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-text-field hide-details :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada Hasta:" ></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
        <v-row justify="center">
          <v-col>
            <v-data-table
          :headers="headers"
          :items="family"
          :search="search"
          sort-by="total_sales"
          :sort-desc="true"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }"
        >
          <template v-slot:item.total_sales="{ item }">
            {{item.total_sales | moneda}}
          </template>
          <template v-slot:item.total_mts2="{item}">
              {{item.total_mts2 | number('0,0.00')}}
          </template>
          <template v-slot:item.total_qty="{item}">
              {{item.total_qty | number('0,0.00')}}
          </template>
          </v-data-table>
          </v-col>
          
        </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {fetchProductionProducts} from '@/api/reports'
import SalesProductGraph from '@/components/reports/SalesProductGraph'
import ExportSalesByFamily from '@/components/reports/ExportSalesByFamily'
import moment from 'moment'
export default {
  name:"ProductSalesReport",
  components:{SalesProductGraph,ExportSalesByFamily},
  created(){
    this.fetch()
  },
  data() {
    return {
      loading: false,
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
      },
      search:'',
      family:[],
      headers:[
          {text: 'Código',align: 'start',value: 'id',class: 'table-header'},
          {text: 'Familia',align: 'start',value: 'name',class: 'table-header'},
          {text: 'Línea',align: 'start',value: 'line',class: 'table-header'},
          {text: 'Mts2',align: 'end',value: 'total_mts2',class: 'table-header'},
          {text: 'Cantidad',align: 'end',value: 'total_qty',class: 'table-header'},
          {text: 'Venta',align: 'end',value: 'total_sales',class: 'table-header'},
        ]
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
      //const {sortBy, sortDesc,page, itemsPerPage }=this.options
      await fetchProductionProducts(created_after,created_before)
      .then((res)=>{
        this.loading=false
        this.family=this.processData(res)
      })
    },
    processData(rawProducts){
      console.log(rawProducts)
      let unique = [...new Set(rawProducts.map(item =>
        item.family
      ))];
      const results=unique.map(family=>{
        const productionProducts=rawProducts.filter(p=> p.family==family)
          //total_sales=productionProducts.reduce((acc, val)=>{ return acc + parseFloat(val.total_sales_price); }, 0)
        return{
          id:family,
          name:productionProducts[0].family_name,
          line:productionProducts[0].line_name,
          total_sales:productionProducts.reduce((acc, val)=>{ return acc + parseFloat(val.total_sales_price); }, 0),
          total_mts2:productionProducts.reduce((acc, val)=>{ return acc + parseFloat(val.mts2); }, 0),
          total_qty:productionProducts.reduce((acc, val)=>{ return acc + parseFloat(val.qty); }, 0),
        }
      })
      return results
    }
  },
  watch:{
    filter:{
      deep: true,
      handler: 'fetch'
    },
  },
  computed:{
    sortedData(){
      const family = this.family
      const bySales = family.sort((a, b) => { return b.total_sales - a.total_sales }).slice(0, 5)
      const byMts2 = family.sort((a, b) => { return b.total_mts2 - a.total_mts2 }).slice(0, 5)
      const byQty = family.sort((a, b) => { return b.total_qty - a.total_qty }).slice(0, 5)
      return {
        bySales: bySales,
        byMts2: byMts2,
        byQty:byQty
      }
    }
  }

}
</script>