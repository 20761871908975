<template>
<v-container>
  <v-row class="mb-4 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo">Productos</h1>
    </v-col>
    <v-col>
      <div class="d-none d-md-flex justify-end">
        <Can I="read" a="Product">
          <export-products :family="family" :line="line" :disabled="loading"><v-icon class="mr-2">fas fa-file-export</v-icon>Exportar</export-products>
        </Can>
        <Can I="update" a="Product">
          <v-btn class="ml-4" color="primary" @click="$router.push({name:'BatchUpdate'})" text>
              <v-icon class="mr-2">fas fa-tags</v-icon>Actualizar
          </v-btn>
        </Can>
        <Can I="create" a="Product">
          <v-btn class="ml-4" color="primary" @click="$router.push({name:'ImportProducts'})" text>
              <v-icon class="mr-2">fas fa-file-import</v-icon>Importar
          </v-btn>
        </Can>
        <Can I="create" a="Product">
          <v-btn class="ml-4" color="success" @click="createUpdate">
              <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
          </v-btn>
        </Can>
      </div>
      <div class="d-md-none d-flex justify-end">
        <v-menu
          offset-y
          bottom left 
              
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
              >
                <v-icon class="ml-2">more_vert</v-icon>
              </v-btn>
              </template>
                <v-list dense>
                  <Can I="create" a="Product">
                    <v-list-item>
                    <v-btn text color="success" @click="createUpdate">
                        <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
                    </v-btn>
                    </v-list-item>
                  </Can>
                  <Can I="read" a="Product">
                    <v-list-item>
                      <export-products :family="family" :line="line" :disabled="loading"><v-icon class="mr-2">fas fa-file-export</v-icon>Exportar</export-products>
                    </v-list-item>
                  </Can>
                  <Can I="update" a="Product">
                    <v-list-item>
                      <v-btn color="primary" @click="$router.push({name:'BatchUpdate'})" text>
                        <v-icon class="mr-2">fas fa-tags</v-icon>Actualizar
                      </v-btn>
                    </v-list-item>
                  </Can>
                  <Can I="create" a="Product">
                    <v-list-item v-if="$can('readCost', 'SalesNote')">
                      <v-btn color="primary" @click="$router.push({name:'ImportProducts'})" text>
                          <v-icon class="mr-2">fas fa-file-import</v-icon>Importar
                      </v-btn>
                    </v-list-item>
                  </Can>
                </v-list>
        </v-menu>

      </div>
    </v-col>
  </v-row>
  <v-card class="pa-4">
    <v-row>
      <v-col>
        <v-select
          v-model="family"
          :items="families"
          item-text="name"
          item-value="id"
          label="Familias"
          placeholder="Todas las Familias"
          single-line
          outlined
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="line"
          :items="lines"
          item-text="name"
          item-value="id"
          label="Lineas"
          placeholder="Todas las Líneas"
          single-line
          outlined
          dense
          clearable
          prepend-inner-icon="filter_alt"
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6">
        <v-text-field
          v-model="search"
          @keyup="searchTimeOut"
          label="Buscar"
          append-icon="fas fa-search"
          :disabled="loading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class=""
          :headers="headers"
          dense
          :items="products"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
          :search="search"
          item-class="table-body"
          @update:options="fetch"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }">
          <template v-slot:item.name="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item.customer="{ item }">
            <v-chip label outlined color='primary' v-if="!item.customer">
              General 
            </v-chip>
            <span v-else>{{item.product_customer.name}}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-switch v-model="item.status" @change="updateStatus(item)" :disabled="!$can('update','Product')"></v-switch>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" text class="ml-2"
                      >more_vert</v-icon
                    >
                  </template>
                  <v-list dense>
                    <v-list-item>
                      <v-btn
                        text
                        small
                        :disabled="!$can('update','Product')"
                        @click.stop="edit(item)" 
                        class="dark_blue--text"
                        ><v-icon class="mr-2">create</v-icon>Modificar</v-btn
                      >
                    </v-list-item>
                    <v-list-item v-if="$can('duplicate', 'SalesNote')">
                      <v-btn
                        text
                        small
                        @click.stop="duplicate(item)"
                        class="dark_blue--text"
                        :disabled="!$can('create','Product')"
                        ><v-icon class="mr-2">fas fa-clone</v-icon
                        >Duplicar</v-btn
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
            <!-- <v-icon  class="mr-2" @click.stop="edit(item)" :disabled="!$can('update','Product')">fas fa-edit</v-icon> -->
          </template>
        </v-data-table>

      </v-col>
    </v-row> 
  </v-card>

  
</v-container>
</template>
<script>
import {fetchProducts, updateProductStatus,fetchFamily,fetchLines} from '@/api/products'
import CustomerComplete from '@/components/customers/CustomerComplete'
import ExportProducts from '@/components/products/products/ExportProducts'
import notifications from '@/utils/notifications'

export default {
  name:'Products',
  components:{CustomerComplete,ExportProducts},
  data:()=>({
    products:[],
    search:'',
    timer:'',
    options:{},
    total:'',
    showDialog:false,
    loading:false,
    lines:[],
    families:[],
    family:'',
    line:'',
    customer:'',
    headers: [
      {
        text: 'Código',
        align: 'start',
        value: 'sku',
        class: 'table-header2'
      },
      {
        text: 'Descripción',
        align: 'start',
        value: 'name',
        class: 'table-header2'
      },
      {
        text:'Cliente',
        value:'customer_name',
        class: 'table-header2'
      },
      {
        text: 'Familia',
        align: 'start',
        value: 'family_name',
        class: 'table-header2'
      },
      {
        text: 'Línea',
        align: 'start',
        value: 'line_name',
        class: 'table-header2'
      },
      {text:'Estatus',value:'status',class: 'table-header2'},
      { text: '',value:'actions',class: 'table-header2',align:'center',sortable: false}
    ],
  }),
  methods:{
    createUpdate(){
      this.$router.push({name:'ProductCreateUpdate',params:{}})
    },
    edit(item){
      this.$router.push({name:'ProductCreateUpdate',params:{product:item.id}})
    },
    duplicate(item){
      this.$router.push({name:'ProductCreateUpdate',params:{product:item.id,duplicate:true}})
    },
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort = ''
      if(sortBy[0]=='family_name'){
        sort = 'family__name'
      }
      else if(sortBy[0]=='line_name'){
        sort = 'line__name'
      }
      else if(sortBy[0]=='customer_name'){
        sort = 'customer__name'
      }
      else {
        sort = sortBy[0]
      }
      if(!this.family) this.family=''
      if(!this.line) this.line=''
      const query=`ordering=${sortDesc[0]?sort:'-'+sort}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&family=${this.family}&line=${this.line}`
      await fetchProducts(query)
      .then((data)=>{
        this.total=data.count
        this.products = data.results
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando productos '+err)
        this.loading=false
      })
    },
    async updateStatus(item){
      console.log(item)
      await updateProductStatus(item.id,item.status)
      .then(()=>{
        notifications.showSuccess('Estatus '+ item.name + ' Actualizado')
      })
      .catch((e)=>{
        notifications.showError('Error Actualizando Estatus '+e)
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.options.page=1
          this.fetch()
      }, 800);
    },
  },
  watch:{
    async family(){
      this.options.page=1
      this.fetch()
    },
    async line(){
      this.options.page=1
      this.fetch()
    },
    async customer(){
      this.options.page=1
      this.fetch()
    },
  },
  async mounted(){
    this.lines=this.$store.getters.getLines
    this.families=this.$store.getters.getFamilies
    this.fetch()
    
  }
}
</script>

<style lang="scss">
  tbody {
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight:normal !important;
    font-size:medium !important;
    color:#707070 !important;
  }
</style>