<template>
  <v-container>
    <v-tabs 
      background-color="transparent"
      color="basil"
      grow
      v-model="tab"
    >
      <v-tab v-model="tab" class="primary--text"><v-icon>tune</v-icon> Configuración</v-tab>
      
      <v-tabs-items v-model="tab">
      <v-tab-item class='pa-6'>
        <company/>
      </v-tab-item>
    </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
import Company from '@/components/parameters/Company'

export default {
  components: { Company },
  name: 'ParametersManager',
  data:()=>({
    tab: null,
  })
}
</script>