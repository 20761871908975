<template>
<v-container>
  <v-skeleton-loader
          v-if="loading"
          v-bind="attrs"
          type="actions,text,text,text,text,card,card"
  ></v-skeleton-loader>
 
  <div v-if="!loading">
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(saveCustomer)">
      <v-row class="mb-10 justify-space-between">
        <v-col>
          <h1 class="text text-h4 titulo"><v-icon class="mr-2">{{customer.id ? 'fas fa-edit':'fas fa-user-plus'}}</v-icon>{{customer.id ? 'Actualizar Cliente':'Crear Cliente'}}</h1>
        </v-col>
        <v-col class="d-flex justify-end">
              <v-switch
              class="mt-0 pt-2 mr-2"
              inset
              v-model="customer.status"
              :color="customer.status ? 'success':'error'"
              :label="customer.status ? 'Activo':'Inactivo'"
              :disabled="!canUpdateMainInformation"
              ></v-switch>
              <v-btn :loading="saveLoading" type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
              <v-btn @click="$router.push('/customers')" text class="ml-2" ><v-icon>fas fa-chevron-left</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-card shaped>
            <v-card-title>Información General</v-card-title>
            <v-card-subtitle>
              {{customer.id ? 'Modifique la información general del cliente.':'Ingrese la información general del cliente'}}
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider name="RUT" rules="required" v-slot="{ errors }">
                    <v-text-field
                    dense
                    label='RUC'
                    prepend-icon="fas fa-id-badge"
                    v-model='customer.rut'
                    
                    outlined
                    @change="()=>{}"
                    :disabled="!canUpdateMainInformation"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider name="Giro" rules="required" v-slot="{ errors }">
                    <v-text-field
                    dense
                    prepend-icon="fas fa-exclamation-circle"
                    label='Giro'
                    v-model='customer.giro'
                    outlined
                    :error-messages="errors"
                    :disabled="!canUpdateMainInformation"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider name="Razón Social" rules="required" v-slot="{ errors }">
                    <v-text-field
                    dense
                    label='Razón Social'
                    prepend-icon="fas fa-file-alt"
                    v-model='customer.name'
                    outlined
                    :error-messages="errors"
                    :disabled="!canUpdateMainInformation"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6" >
                  <ValidationProvider name="Fantasía" rules="required" v-slot="{ errors }">
                    <v-text-field
                    dense
                    label='Nombre de Fantasía'
                    prepend-icon="fas fa-hat-wizard"
                    v-model='customer.fantasy'
                    :disabled="!canUpdateMainInformation"
                    outlined
                    :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="Teléfono" rules="required" v-slot="{ errors }">
                <v-text-field
                dense
                label='Teléfono'
                prepend-icon="fas fa-phone"
                v-model='customer.phone'
                outlined
                :error-messages="errors"
                :disabled="!canUpdateMainInformation"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="Condición de Pago" rules="required" v-slot="{ errors }">
                <v-text-field
                dense
                label='Condición de Pago (Días)'
                v-model='customer.payment'
                prepend-icon="fas fa-calendar-alt"
                type='number'
                :min="0"
                outlined
                :error-messages="errors"
                :disabled="!canUpdateMainInformation"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="Vendedor" rules="required" v-slot="{ errors }">
                <seller-selection 
                  v-model="customer.seller"
                  :disabled="!canUpdateMainInformation"
                  icon="fas fa-id-badge"
                  :errors="errors"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              
                <tipo-selection 
                  v-model="customer.tipo"
                  :filtro="false"
                  icon="fas fa-id-badge"
                  :disabled="!canUpdateMainInformation"
                  />
             
            </v-col>
            <v-col cols="12" md="6">
             
                <industry-selection 
                  v-model="customer.industry"
                  icon="fas fa-space-shuttle"
                  :disabled="!canUpdateMainInformation"
                  :filtro="false"
                  />
              
            </v-col>
            <v-col col="12" md="6">
              
                <rubro-selection 
                  v-model="customer.rubro"
                  icon="fas fa-gifts"
                  :disabled="!canUpdateMainInformation"
                  :filtro="false"
                  />
              
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="Comisión" rules="required" v-slot="{ errors }">
                <v-text-field
                dense
                label='Comisión de Agencia'
                prepend-icon="mdi-chart-timeline-variant"
                v-model='customer.com_agencia'
                :disabled="!canUpdateMainInformation"
                type='number'
                :min="0"
                outlined
                :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
              </v-row>
            </v-card-text>
          </v-card>
    </v-form>
    </ValidationObserver>
  </div>
  <v-row v-if="customer.id">
    <v-col cols="12">
      <v-card shaped>
        <v-card-title>Contactos</v-card-title>
        <v-card-subtitle>Lista de Contactos</v-card-subtitle>
        <v-card-text>
          <contact-list v-model="customer.contacts" :customerId="customer.id"/>
        </v-card-text>

      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card shaped>
        <v-card-title>Direcciones</v-card-title>
        <v-card-subtitle>Lista de Direcciones</v-card-subtitle>
        <v-card-text>
          <address-list v-model="customer.addresses" :customerId="customer.id"/>
        </v-card-text>

      </v-card>
    </v-col>
  </v-row>
  <!-- <v-row>
    <v-col>
      <v-card>
        <v-card-title class="justify-space-between">
          Direcciones
          <v-btn medium color="success" @click="addAddress=true"><v-icon>fas fa-plus</v-icon></v-btn>
        </v-card-title>
        <v-expansion-panels>
          <v-expansion-panel v-for="address in customer.addresses" :key="address.name">
            <v-expansion-panel-header>
                <div>{{address.name}}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pa-2"><v-icon class="mr-2">fas fa-road</v-icon>{{address.street}} {{address.number}} {{address.suite}}</v-row>
              <v-row class="pa-2 justify-space-between">
                <div>
                  <v-icon class="mr-2">fas fa-map-marked-alt</v-icon>{{address.comune}} {{address.region}}
                </div>
                <v-icon small class="error--text" @click="removeAddress(address)">fas fa-trash</v-icon></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    
    <v-col>
      <v-card>
        <v-card-title class="justify-space-between">
          Contactos
          <v-btn medium color="success" @click="addContact=true"><v-icon>fas fa-plus</v-icon></v-btn>
        </v-card-title>
        <v-expansion-panels>
          <v-expansion-panel v-for="contact in customer.contacts" :key="contact.email">
            <v-expansion-panel-header>
                <div>{{`${contact.first_name} ${contact.last_name}`}}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pa-2"><v-icon class="mr-2">fas fa-envelope</v-icon>{{contact.email}}</v-row>
              <v-row class="pa-2"><v-icon class="mr-2">fas fa-phone</v-icon>{{contact.phone}}</v-row>
              <v-row class="pa-2 justify-space-between">
                <div>
                  <v-icon class="mr-2">fas fa-building-user</v-icon>{{contact.cargo}}
                </div>
                <v-icon small class="error--text" @click="deleteContact(contact)" >fas fa-trash</v-icon></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row> -->
  <!-- <v-dialog v-model="addAddress" max-width="500">
    <v-card class="pa-6">
      <v-card-title><v-icon class="mr-2">fas fa-plus</v-icon>Dirección</v-card-title>
      <address-create-update @add="addNewAddress" @close="addAddress=false"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addContact" max-width="500">
    <v-card class="pa-6">
      <v-card-title><v-icon class="mr-2">fas fa-plus</v-icon>Contacto</v-card-title>
      <contact-create-update @add="addNewContact" @close="addContact=false"/>
    </v-card>
  </v-dialog> -->
  <confirm 
    v-model="showConfirm" 
    message="El RUT ingresado ya existe. Desea crear una sucursal?" 
    @ok="()=>{
      if(customer.id) modifyCustomer()
      else addNewCustomer()
      }"
    @cancel="()=>{customer.branch_office=0;showConfirm=false}"/>
</v-container>
</template>

<script>
import regions_comunes from '@/utils/regions_comunes.json'
import SellerSelection from '@/components/customers/SellerSelection'
import TipoSelection from '@/components/customers/TipoSelection.vue'
import IndustrySelection from '@/components/customers/IndustrySelection.vue'
import ContactList from '@/components/customers/ContactList.vue'
import AddressList from '@/components/customers/AddressList.vue'
import RubroSelection from '@/components/customers/RubroSelection.vue'
import {getCustomer,createCustomer, updateCustomer,createAddress,deleteAddress,createContact,deleteContact,fetchCustomers} from '@/api/customers'
import AddressCreateUpdate from '@/components/customers/AddressCreateUpdate'
import ContactCreateUpdate from '@/components/customers/ContactCreateUpdate'
import notifications from '@/utils/notifications'
import {validaRUT }from '@/utils/rut_validator'


export default {
  name:'CreateupdateCustomer',
  components: { ContactList,AddressList,SellerSelection,AddressCreateUpdate, ContactCreateUpdate,TipoSelection,IndustrySelection,RubroSelection},
  data:()=>({
    customer:{
      addresses:[],
      contacts:[],
      seller:'',
      status:true,
      branch_office:0,
      tipo:'',
      industry:'',
      rubro:'',
    },
    addAddress:false,
    address:'',
    addContact:false,
    contact:'',
    alerta:true,
    saveLoading:false,
    loading:false,
    showConfirm:false,
    rules:{
      rut:value=>validaRUT(value)||'Rut Inválido'
    }
  }),
  
  async created(){
    if(this.$route.params.customerId){
      this.loading=true
      await getCustomer(this.$route.params.customerId)
      .then((res)=>{
        this.customer = res
        this.customer.seller=this.customer.seller?this.customer.seller:''
        this.customer.tipo=this.customer.tipo?this.customer.tipo:''
        this.customer.industry=this.customer.industry?this.customer.industry.id:''
        this.customer.rubro=this.customer.rubro?this.customer.rubro.id:''
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando datos del cliente')
      })
    }
  },
  methods:{
    async saveCustomer(){
      if(!this.rutValido){
        notifications.showError('Error de validación','RUT Inválido')
        return 0
      }
      if(this.customer.id){
        this.modifyCustomer()
        return 0
      }
      if(this.saveValidation){
        //Verifica si el rut ya existe
        await fetchCustomers('',1,'','','','',this.customer.rut)
        .then((res)=>{
          if(res.count>0){
            this.customer.branch_office=res.count+1
            this.showConfirm=true
          }
          else {
            this.addNewCustomer()
          }
        })
        .catch(()=>{
          notifications.showError('Error','Error al verificar datos datos del cliente')
        })
      }
    },
    async modifyCustomer(){
      this.saveLoading=true
        await updateCustomer(this.customer)
        .then(()=>{
          notifications.showSuccess('Cliente '+ this.customer.name + ' Actualizado')
          this.$router.push('/customers')
          this.saveLoading=false
        })
        .catch(()=>{
          notifications.showError('Error', 'Error actualizando el cliente')
          this.saveLoading=false
        })
    },
    async addNewCustomer(){
     
      this.saveLoading=true
        await createCustomer(this.customer)
        .then(async customer=>{
          notifications.showSuccess('Éxito!','Cliente '+ this.customer.name + ' creado')
          this.$router.push('/customers/create-update/'+customer.id)
          this.saveLoading=false
        })
        .catch((error)=>{
          notifications.showError('Error','Error creando el cliente')
          this.saveLoading=false
        })
    },
  },
  computed:{
    saveValidation(){
      return true
    },
    rutValido(){
      // var valido = false
      // if(this.customer.rut){
      //   valido = validaRUT(this.customer.rut)
      // }
      //No valida el RUT por versión PERU
      return true
    },
    canUpdateMainInformation(){
      return this.$ability.can('update_main_information','Customer')
    }
  }
}
</script>