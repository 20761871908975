<template>
  <div>
    <v-btn small @click="show_dialog=true" :disabled="value">Prueba de configuración</v-btn>
    <v-dialog v-model="show_dialog" max-width="400">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <div>
            <span>Prueba de configuración</span>
          </div>
          <v-icon @click="()=>show_dialog=false" >mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle>
          Ingrese un correo para enviar una prueba
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
          max-width="200px"
            v-model="email_address"
            label="Correo"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center p-2">
          <v-btn color="success" :loading="loading" @click="test_email">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {testEmail} from '@/api/parameters'
import notifications from '@/utils/notifications'
export default {
  name: 'EmailTest',
  props: {
    value: Boolean,
    email_host: String,
    email_port: String,
    email_user: String,
    email_password: String,
    email_port: String,
    email_from: String,
    email_smtp_encryption: String,
  },
  data: () => ({
    show_dialog: false,
    loading: false,
    email_address: '',
  }),
  computed: {
    tested() {
      return this.email_tested
    }
  },
  methods: {
    async test_email() {
      this.loading = true
      const configuration = {
        email_host: this.email_host,
        email_port: this.email_port,
        email_user: this.email_user,
        email_password: this.email_password,
        email_from: this.email_from,
        email_smtp_encryption: this.email_smtp_encryption,
      }
      await testEmail({to:this.email_address, configuration: configuration})
      .then((response) => {
        notifications.showSuccess('Correo enviado correctamente','Revise su buzón de entrada para verificar que el correo ha sido enviado')
        this.$emit('input', true)
      })
      .catch((error) => {
        notifications.showError('Error al enviar correo',error)
        this.$emit('input', false)
      })
      .finally(() => {
        this.loading = false
        this.show_dialog = false
      })
    }
  },
}
</script>